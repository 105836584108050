<tariff-check-header></tariff-check-header>
<mat-toolbar class="second-toolbar">
  <button mat-icon-button (click)="back()" data-cy="back">
    <mat-icon fontSet="fas" fontIcon="fa-arrow-left" color="primary"></mat-icon>
  </button>
  <div class="toolbar-title theme-color-primary center" data-cy="title">
    Meine Daten: Auf einen Blick
  </div>
</mat-toolbar>
<main>
  <mat-card>
    @if(this.userDetails) {
    <mat-tab-group animationDuration="0ms">
      @if(!this.userDetails.anonymous) {
      <mat-tab label="Account">
        <form [formGroup]="userAccountForm" novalidate>
          <div class="flex-row flex-col-sm">
            <mat-card class="flat-card flex-grow">
              <mat-card-header>
                <mat-card-title>Hauptdaten</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field appearance="outline">
                  <mat-label>Anrede</mat-label>
                  <mat-select
                    type="text"
                    aria-label="Anrede"
                    formControlName="genderControl"
                  >
                    <mat-option value="m">Herr</mat-option>
                    <mat-option value="f">Frau</mat-option>
                    <mat-option value="d">Divers</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Vorname</mat-label>
                  <input
                    type="text"
                    aria-label="Vorname"
                    matInput
                    formControlName="firstNameControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Nachname</mat-label>
                  <input
                    type="text"
                    aria-label="Nachname"
                    matInput
                    formControlName="lastNameControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>E-Mail</mat-label>
                  <input
                    type="email"
                    aria-label="E-Mail"
                    matInput
                    formControlName="emailControl"
                  />
                </mat-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="flat-card flex-grow">
              <mat-card-header>
                <mat-card-title>Zusatzdaten</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field appearance="outline">
                  <mat-label>Telefon Festnetz</mat-label>
                  <input
                    type="text"
                    aria-label="Telefon Festnetz"
                    matInput
                    formControlName="phoneControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Berufsbezeichnung</mat-label>
                  <input
                    type="text"
                    aria-label="Berufsbezeichnung"
                    matInput
                    formControlName="positionControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Vermittlernummer</mat-label>
                  <input
                    type="text"
                    aria-label="Vermittlernummer"
                    matInput
                    formControlName="broker_noControl"
                  />
                </mat-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="flat-card flex-grow">
              <mat-card-header>
                <mat-card-title></mat-card-title>
              </mat-card-header>
              <mat-card-content> </mat-card-content>
            </mat-card>
          </div>
          <mat-card-actions>
            <button mat-button (click)="userAccountForm.reset()">
              Zurücksetzen
            </button>
            <button
              mat-flat-button
              color="primary"
              [disabled]="!userAccountForm.valid"
              (click)="saveUserAccount(userAccountForm, companyAccountForm)"
            >
              Speichern
            </button>
          </mat-card-actions>
        </form>
      </mat-tab>
      } @if(!this.userDetails.anonymous) {
      <mat-tab label="Unternehmen">
        <form [formGroup]="companyAccountForm" novalidate>
          <div class="flex-row flex-col-sm">
            <mat-card class="flat-card flex-grow">
              <mat-card-header>
                <mat-card-title>Adressdaten</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field appearance="outline">
                  <mat-label>Firma</mat-label>
                  <input
                    type="text"
                    aria-label="Firma"
                    matInput
                    formControlName="companyNameControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Straße</mat-label>
                  <input
                    type="text"
                    aria-label="Straße"
                    matInput
                    formControlName="streetControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Nummer</mat-label>
                  <input
                    type="text"
                    aria-label="nummer"
                    matInput
                    formControlName="streetNumberControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>PLZ</mat-label>
                  <input
                    type="text"
                    aria-label="PLZ"
                    matInput
                    formControlName="zipControl"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Ort</mat-label>
                  <input
                    type="text"
                    aria-label="Ort"
                    matInput
                    formControlName="cityControl"
                  />
                </mat-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="flat-card flex-grow">
              <mat-card-header>
                <mat-card-title>Unternehmens-Logo</mat-card-title>
                <mat-card-subtitle
                  >welche auf Druckstücken angezeigt wird</mat-card-subtitle
                >
              </mat-card-header>
              <mat-card-content>
                @if(isLogoLoaded) {
                <img [src]="logo" class="header-logo company-logo" alt="logo" />
                } @else {
                <img
                  src="assets/img/logos/default.svg"
                  class="header-logo company-logo"
                  alt="logo"
                />
                }

                <div>
                  <mat-label>Logo hinzufügen</mat-label>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    (change)="onFileSelected($event)"
                  />
                  <p class="mat-hint logo-hint">
                    Größe: max. 5MB, Formate: .png, .jpeg, .jpg
                  </p>
                </div>
                <div>
                  <mat-label>Standard Logo</mat-label>
                  <div class="remove-button-container">
                    <button mat-button (click)="removeUserLogo()">
                      <mat-icon
                        fontSet="fas"
                        fontIcon="fa-rotate-left"
                      ></mat-icon>
                      Wiederherstellen
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="flat-card flex-grow">
              <mat-card-header>
                <mat-card-title></mat-card-title>
              </mat-card-header>

              <mat-card-content></mat-card-content>
            </mat-card>
          </div>
          <mat-card-actions>
            <button mat-button (click)="userAccountForm.reset()">
              Zurücksetzen
            </button>
            <button
              mat-flat-button
              color="primary"
              [disabled]="!companyAccountForm.valid"
              (click)="saveUserAccount(userAccountForm, companyAccountForm)"
            >
              Speichern
            </button>
          </mat-card-actions>
        </form>
      </mat-tab>
      }

      <mat-tab label="Checks">
        <div class="flex-row flex-col-md">
          <mat-card class="flat-card flex-grow">
            <mat-card-header>
              <mat-card-title>Ihre Checks {{ this.year }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas
                baseChart
                class="chart chart-bar"
                [data]="barChartData"
                [options]="barChartOptions"
                [type]="barChartType"
                [plugins]="barChartPlugins"
              ></canvas>
              <div class="flex-row gap-16">
                <button
                  mat-button
                  (click)="getConsumptionYearly(this.year - 1)"
                >
                  <mat-icon fontSet="fas" fontIcon="fa-chevron-left"></mat-icon>
                  {{ this.year - 1 }}
                </button>
                @if(this.year != this.currentYear) {
                <button
                  mat-button
                  (click)="getConsumptionYearly(this.year + 1)"
                >
                  {{ this.year + 1 }}
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-chevron-right"
                    iconPositionEnd
                  ></mat-icon>
                </button>
                }
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="flat-card flex-grow">
            <mat-card-header>
              <mat-card-title>Ihre Checks Verteilung</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <canvas
                baseChart
                class="chart chart-pie"
                [data]="pieChartData"
                [options]="pieChartOptions"
                [type]="pieChartType"
              >
              </canvas>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      @if(!this.userDetails.anonymous) {
      <mat-tab label="Passwort">
        <form [formGroup]="passwordAccountForm" novalidate>
          <div class="flex-row flex-col-sm">
            <mat-card class="flat-card">
              <mat-card-header>
                <mat-card-title>Passwort ändern</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <small
                  >* die mit Stern markierten Felder sind Pflichtfelder</small
                >
                <br />
                <br />
                <mat-form-field appearance="outline">
                  <mat-label>Derzeitiges Passwort</mat-label>
                  <input
                    data-cy="currentPasswordControl"
                    [type]="currentPasswordHidden ? 'password' : 'text'"
                    aria-label="Derzeitiges Passwort"
                    matInput
                    formControlName="currentPasswordControl"
                    (focusout)="focusOutCurrentPassword()"
                  />
                  <mat-icon
                    matSuffix
                    (click)="currentPasswordHidden = !currentPasswordHidden"
                    >{{
                      currentPasswordHidden ? 'visibility_off' : 'visibility'
                    }}</mat-icon
                  >
                  <mat-error>Das derzeitige Passwort ist ungültig</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Neues Passwort</mat-label>
                  <input
                    data-cy="newPasswordControl"
                    [type]="newPasswordHidden ? 'password' : 'text'"
                    aria-label="Neues Passwort"
                    matInput
                    formControlName="newPasswordControl"
                  />
                  <mat-icon
                    matSuffix
                    (click)="newPasswordHidden = !newPasswordHidden"
                    >{{
                      newPasswordHidden ? 'visibility_off' : 'visibility'
                    }}</mat-icon
                  >
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Neues Passwort bestätigen</mat-label>
                  <input
                    data-cy="newPasswordRepeatControl"
                    [type]="newPasswordRepeatHidden ? 'password' : 'text'"
                    aria-label="Neues Passwort bestätigen"
                    matInput
                    formControlName="newPasswordRepeatControl"
                    (focusout)="focusOutNewPassword()"
                  />
                  <mat-icon
                    matSuffix
                    (click)="newPasswordRepeatHidden = !newPasswordRepeatHidden"
                    >{{
                      newPasswordRepeatHidden ? 'visibility_off' : 'visibility'
                    }}</mat-icon
                  >
                  <mat-error>Passwörter stimmen nicht überein </mat-error>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-card-actions>
            <button mat-button (click)="passwordAccountForm.reset()">
              Zurücksetzen
            </button>
            <button
              mat-flat-button
              data-cy="saveNewPassword"
              color="primary"
              [disabled]="!passwordAccountForm.valid"
              (click)="savePasswordAccount(passwordAccountForm)"
            >
              Passwort ändern
            </button>
          </mat-card-actions>
        </form>
      </mat-tab>
      }
    </mat-tab-group>
    }
  </mat-card>
</main>
