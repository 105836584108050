import { AccountComponent } from './account/account.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ProductSelectComponent } from './product-select/product-select.component';
import { TariffSelectComponent } from './tariff-select/tariff-select.component';
import { FormTariffComponent } from './form/form-tariff/form-tariff.component';
import { CriteriaComponent } from './criteria/criteria.component';
import { PasswordComponent } from './password/password.component';
import { DonutComponent } from './donut/donut.component';
import { PrintComponent } from './print/print.component';
import { TariffCheckComponent } from './tariff-check/tariff-check.component';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProductNavigationComponent } from './sidenavs/product-navigation/product-navigation.component';
import { TariffReportComponent } from './sidenavs/tariff-report/tariff-report.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SsoComponent } from './sso/sso.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { DialogLegalsComponent } from './dialog/dialog-legals/dialog-legals.component';
import { DialogSupportComponent } from './dialog/dialog-support/dialog-support.component';
import { DialogCriterionComponent } from './dialog/dialog-criterion/dialog-criterion.component';
import { DialogPasswordComponent } from './dialog/dialog-password/dialog-password.component';
import { DialogTestComponent } from './dialog/dialog-test/dialog-test.component';
import { DialogInfoComponent } from './dialog/dialog-info/dialog-info.component';
import { DialogTariffComponent } from './dialog/dialog-tariff/dialog-tariff.component';
import { DialogProductComponent } from './dialog/dialog-product/dialog-product.component';
import { DialogSurveyComponent } from './dialog/dialog-survey/dialog-survey.component';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import {
  TariffService,
  ProductService,
  RemoveExtraCritPipe,
  RemoveAGPipe,
} from '@smart-check/feature-comparison';
import { SharedUtilAnalyticsModule } from '@smart-check/shared/analytics';
import {
  AuthService,
  AuthGuardService,
  AngularMaterialModule,
  UtilsService,
  ServerErrorInterceptor,
  GlobalErrorHandler,
  SecurityService,
} from '@smart-check/shared/ui';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    SsoComponent,
    ImpersonateComponent,
    DialogTestComponent,
    DialogLegalsComponent,
    DialogSupportComponent,
    DialogCriterionComponent,
    DialogInfoComponent,
    DialogTariffComponent,
    DialogPasswordComponent,
    DialogProductComponent,
    DialogSurveyComponent,
    ComplianceComponent,
    ProductSelectComponent,
    TariffSelectComponent,
    ProductNavigationComponent,
    TariffReportComponent,
    TariffCheckComponent,
    AccountComponent,
    CriteriaComponent,
    DonutComponent,
    PrintComponent,
    FormTariffComponent,
    PasswordComponent,
    RemoveExtraCritPipe,
    RemoveAGPipe,
  ],
  bootstrap: [AppComponent],
  exports: [
    HeaderComponent,
    ProductSelectComponent,
    TariffSelectComponent,
    ProductNavigationComponent,
    TariffReportComponent,
    DialogTestComponent,
    DialogPasswordComponent,
    DialogLegalsComponent,
    DialogSupportComponent,
    DialogCriterionComponent,
    DialogInfoComponent,
    DialogTariffComponent,
    DialogSurveyComponent,
    AccountComponent,
    CriteriaComponent,
    DonutComponent,
    PrintComponent,
    FormTariffComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    SharedUtilAnalyticsModule,
  ],
  providers: [
    AuthService,
    AuthGuardService,
    UtilsService,
    ProductService,
    TariffService,
    SecurityService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: '860px' , width: '860px' } },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
