<mat-toolbar
  class="mat-toolbar mat-toolbar-single-row first-toolbar flex-row justify-between"
>
  <div>
    <a routerLink="/productSelect" data-cy="logo">
      @if (isLogoLoaded) {
      <img [src]="logo" class="header-logo" alt="logo" />
      } @else {
      <img src="assets/img/logos/default.svg" class="header-logo" alt="logo" />
      }
    </a>
  </div>
  <div>
    <button
      mat-mini-fab
      aria-label="menu"
      tabindex="-1"
      [matMenuTriggerFor]="menu"
      data-cy="profile"
    >
      @if (userInitials) {
      <ng-container>
        {{ userInitials }}
      </ng-container>
      } @else {
      <mat-icon fontSet="fas" fontIcon="fa-user-tie"></mat-icon>
      }
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item data-cy="account" routerLink="/verwaltung">
        <mat-icon fontSet="fas" fontIcon="fa-id-card"></mat-icon>
        Meine Daten
      </button>
      <mat-divider class="mat-divider"></mat-divider>
      <button mat-menu-item data-cy="criterion" routerLink="/criterionList">
        <mat-icon fontSet="fas" fontIcon="fa-list-dropdown"></mat-icon>
        Kriterien Überblick
      </button>
      <button mat-menu-item data-cy="support" (click)="openDialog('support')">
        <mat-icon fontSet="fas" fontIcon="fa-question-circle"></mat-icon>
        Support
      </button>
      <button mat-menu-item data-cy="legals" (click)="openDialog('legals')">
        <mat-icon fontSet="fas" fontIcon="fa-landmark"></mat-icon>
        Rechtliche Informationen
      </button>
      <mat-divider class="mat-divider"></mat-divider>
      <button mat-menu-item data-cy="logout" (click)="logout()">
        <mat-icon
          fontSet="fas"
          fontIcon="fa-arrow-up-left-from-circle"
        ></mat-icon>
        Abmelden
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
