import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, UserDetails } from '@smart-check/feature-comparison';
import { ThemeService, AuthService } from '@smart-check/shared/ui';

@Component({
  selector: 'compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss'],
})
export class ComplianceComponent implements OnInit {
  public user = JSON.parse(localStorage.getItem('user')!);
  public userDetails: UserDetails;
  public userGroupId = -1;
  public acceptedGdprChecked: boolean = false;
  public wantsWerbungChecked: boolean = false;
  public wantsWerbung: number = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private themeService: ThemeService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.themeService.setTheme(this.userGroupId);
    this.themeService.setFavicon(this.userGroupId);
  }

  complianceRefused() {
    this.authService.logout();
    this.router.navigate(['home']);
  }

  complianceAccepted() {
    if (this.wantsWerbungChecked) {
      this.wantsWerbung = 1;
    }
    const userDetails: UserDetails = {
      acceptedGdpr: 1,
      wantsWerbung: this.wantsWerbung,
    };
    this.userService.setGdprAds(userDetails).subscribe({
      next: (response) => {
        if (response === 0) {
          this.router.navigate(['/productSelect']);
        }
      },
    });
  }
}
