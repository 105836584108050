import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SidenavService,
  Sidenav,
  ProductType,
  ProductService,
  TariffSelection,
} from '@smart-check/feature-comparison';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tariff-select',
  templateUrl: './tariff-select.component.html',
})
export class TariffSelectComponent implements OnInit {
  public sideNavigation: Sidenav = {
    id: 0,
    title: '',
  };
  public currentProductType: ProductType = {
    id: 0,
    name: '',
    slug: '',
    alternative_slug: '',
    import_key: '',
    product_type_sparte_id: 0,
    position: 0,
    icon: '',
    reference_tariff_id: 0,
    criterionCount: 0,
    criterions: [],
  };
  public data: TariffSelection = {
    currentProductType: this.currentProductType,
    currentTariff: undefined,
    offerTariff: undefined,
    isOffer: false,
  };
  constructor(
    private route: ActivatedRoute,
    private sidenavService: SidenavService,
    private productService: ProductService
  ) {}

  @ViewChild('sidenav') sidenav: MatSidenav;

  ngOnInit(): void {
    this.currentProductType.id = parseInt(
      this.route.snapshot.paramMap.get('product')!
    );

    this.productService
      .getCurrentProductType(this.currentProductType.id)
      .subscribe({
        next: (response: ProductType) => {
          this.data.currentProductType = response;
        },
      });
  }

  toggleSidenavForm($event: any) {
    this.toggleSidenav($event);
  }

  toggleSidenav(id: number) {
    this.sideNavigation = this.sidenavService.getSidenav(id);
    if (this.sidenav) {
      this.sidenav.open();
    }
  }
}
