import { Directive, ElementRef, Input } from '@angular/core';
import { EventProperties, PosthogService } from '../posthog.service';

@Directive({
  selector: '[analyticsEvent]',
})
export class CustomEventDirective {
  @Input() analyticsEvent: string;

  @Input() analyticsData: EventProperties;

  constructor(
    private readonly service: PosthogService,
    private readonly element: ElementRef
  ) {
    // Unfortunately, it's not possible to use @HostListener for this, since routerLinks prevent angulars click events from bubbling, so we need to use native html event listeners
    (this.element.nativeElement as HTMLElement).addEventListener(
      'click',
      this.onClick.bind(this)
    );
  }

  onClick() {
    this.service.capture(this.analyticsEvent, this.analyticsData);
  }
}
