import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, User } from '@smart-check/shared/ui';
import { PosthogService } from '@smart-check/shared/analytics';

@Component({
  selector: 'smart-check-sso',
  template: '',
})
export class SsoComponent implements OnInit {
  public user: User;
  public ssoToken: string;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private posthogService: PosthogService
  ) {}

  ngOnInit(): void {
    this.ssoToken = this.route.snapshot.paramMap.get('ssoToken')!;
    this.authService.getSsoIdentity(this.ssoToken).subscribe({
      next: (response: User) => {
        this.user = response;
      },
      complete: () => {
        if (this.user.currentUser.csrfToken) {
          localStorage.setItem('user', JSON.stringify(this.user.currentUser));
          const sessionUser = JSON.parse(localStorage.getItem('user')!);
          localStorage.setItem('scheme', sessionUser.scheme.colorScheme);
          this.posthogService.identifyUser(this.user.currentUser);

          this.navigate(
            this.user.productTypeId?.toString(),
            this.user.currentTariff?.toString(),
            this.user.offerTariff?.toString()
          );
        }
      },
    });
  }

  navigate(
    productTypeId: string | undefined,
    currentTariff: string | undefined,
    offerTariff: string | undefined
  ): void {
    if (currentTariff !== '-1' && offerTariff === '-1') {
      this.router.navigate(['tariffCheck', productTypeId, currentTariff]);
    } else if (currentTariff === '-1' && offerTariff !== '-1') {
      this.router.navigate(['tariffCheck', productTypeId, 'null', offerTariff]);
    } else if (currentTariff !== '-1' && offerTariff !== '-1') {
      this.router.navigate([
        'tariffCheck',
        productTypeId,
        currentTariff,
        offerTariff,
      ]);
    } else {
      this.router.navigate(['productSelect']);
    }
  }
}
