<div class="donut-container">
  @if(tariff) {
  <div lass="svg-item">
    <svg viewBox="0 0 40 40" class="donut">
      <defs>
        <filter
          id="dropshadow"
          x="-40%"
          y="-40%"
          width="180%"
          height="180%"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="5" dy="5" result="offsetblur" />
          <feOffset dx="-5" dy="-5" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.2" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="insetshadow">
          <feOffset dx="0" dy="1" />
          <feGaussianBlur stdDeviation="1" result="offset-blur" />
          <feComposite
            operator="out"
            in="SourceGraphic"
            in2="offset-blur"
            result="inverse"
          />
        </filter>
      </defs>
      <circle class="donut-hole" cx="20" cy="20" r="16" fill="#fff"></circle>
      <circle
        class="donut-ring"
        cx="20"
        cy="20"
        r="16"
        fill="transparent"
        stroke-width="4"
        style="filter: url(#insetshadow)"
        stroke="#f2f2f6"
      ></circle>
      <circle
        class="donut-segment donut-segment-2"
        cx="20"
        cy="20"
        r="16"
        fill="transparent"
        stroke-width="4"
        stroke-dashoffset="25"
        [attr.stroke]="color"
        [attr.stroke-dasharray]="scoreDiff"
        [@keyframes]="{ value: scoreDiff, params: { score: scoreDiff } }"
        style="filter: url(#dropshadow)"
      ></circle>
      <g class="donut-text donut-text-1">
        <text y="49%" transform="translate(0, 2)">
          <tspan x="50%" text-anchor="middle" class="donut-percent">
            {{ tariff.criterions.score_reached }} /
            {{ tariff.criterions.score_possible }}
          </tspan>
        </text>
        <text y="58%" transform="translate(0, 2)">
          <tspan x="50%" text-anchor="middle" class="donut-data">
            Kriterien erfüllt
          </tspan>
        </text>
      </g>
    </svg>
  </div>
  } @else {
  <div class="svg-item">
    <svg class="empty" viewBox="0 0 40 40">
      <g>
        <defs></defs>
        <defs>
          <filter id="dropshadow-0.2307475729369921">
            <feGaussianBlur
              in="SourceAlpha"
              stdDeviation="1"
              result="blur"
            ></feGaussianBlur>
            <feOffset in="blur" dx="1" dy="2" result="offsetBlur"></feOffset>
            <feMerge>
              <feMergeNode in="offsetBlur"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
      </g>
    </svg>
  </div>
  }
</div>
