import { Component, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@smart-check/feature-comparison';
import { NotificationService } from '@smart-check/shared/ui';

@Component({
  selector: 'dialog-password',
  templateUrl: './dialog-password.component.html',
  styleUrls: ['./../../home/home.component.scss'],
})
export class DialogPasswordComponent {
  public requestPasswordForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogPasswordComponent>,
    private userService: UserService,
    private injector: Injector
  ) {
    this.requestPasswordForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$'),
      ]),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  requestPassword(requestPasswordForm: FormGroup) {
    const newPW: string = requestPasswordForm.get('email')?.value;
    this.userService.requestPassword(newPW).subscribe({
      next: (response) => {
        if (response === 0) {
          const notifier = this.injector.get(NotificationService);
          notifier.showSuccess(
            'Sie erhalten eine E-Mail mit einem Link, um Ihr Passwort neu zu setzen.'
          );
          this.dialogRef.close();
        }
      },
    });
  }
}
