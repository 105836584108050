<tariff-check-header></tariff-check-header>
<mat-toolbar class="second-toolbar">
  <button
    mat-icon-button
    data-cy="back"
    routerLink="/tariffSelect/{{ currentProductType.id }}"
  >
    <mat-icon fontSet="fas" fontIcon="fa-arrow-left"></mat-icon>
  </button>
  <div class="toolbar-title theme-color-primary center" data-cy="title">
    <div class="progress-bar" (click)="loader()"></div>
    <span class="hide-xs"
      >{{ getProductFullName(currentProductType.id) }} Bewertung</span
    >
    <span class="hide-gt-xs">{{
      getProductShortName(currentProductType.id)
    }}</span>
  </div>
  <button
    mat-icon-button
    (click)="toggleSidenav(0)"
    data-cy="product-navigation"
    matTooltip="Produktauswahl"
  >
    <mat-icon fontSet="fas" fontIcon="fa-list-radio"></mat-icon>
  </button>
  <button mat-icon-button (click)="toggleSidenav(1)" data-cy="tariff-report"  matTooltip="Tarif melden">
    <mat-icon fontSet="fas" fontIcon="fa-bullhorn"></mat-icon>
  </button>
  @if (currentTariff || offerTariff) {
  <button mat-icon-button (click)="openPdfPreview()" data-cy="tariff-print" matTooltip="exportieren">
    <mat-icon fontSet="fas" fontIcon="fa-print"></mat-icon>
  </button>
  } @if (currentProductType.id === 25) {
  <button
    mat-icon-button
    (click)="openDialog()"
    matTooltip="Beihilfesachverhalt"
  >
    <mat-icon
      fontSet="fas"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </button>
  }
</mat-toolbar>
<mat-drawer-container hasBackdrop="true">
  <mat-drawer
    #sidenav
    opened="false"
    position="end"
    mode="over"
    fixedTopGap="56"
  >
    <div class="flex-row justify-between">
      <span class="drawer-title" data-cy="drawer-title">{{
        sideNavigation.title
      }}</span>
      <button mat-icon-button (click)="sidenav.close()">
        <mat-icon fontSet="far" fontIcon="fa-times" data-cy="close"></mat-icon>
      </button>
    </div>
    @if (sideNavigation.id == 0) {
    <product-navigation></product-navigation>
    } @if (sideNavigation.id == 1) {
    <tariff-report></tariff-report>
    }
  </mat-drawer>
  <main>
    @if (currentProductType) {
    <div class="card-container">
      <mat-card class="card-select-tariff">
        <mat-icon
          class="card-icon"
          [ngClass]="status ? 'hidden' : 'show'"
          fontSet="fas"
          [fontIcon]="currentProductType.icon"
        ></mat-icon>
        <div class="card-icon" [ngClass]="status ? 'show' : 'none'">
          <div class="loader">
            <i class="first fas fa-chess-pawn m-left" aria-hidden="true"></i>
            <i class="second fas fa-chess-knight m-left" aria-hidden="true"></i>
            <i class="third fas fa-chess-rook m-left" aria-hidden="true"></i>
            <i class="fourth fas fa-chess-queen" aria-hidden="true"></i>
          </div>
        </div>
        <div class="flex-row justify-between gap-0">
          <mat-card class="flat-card flex-grow-50">
            <mat-card-title>Aktueller Tarif</mat-card-title>
            @if (currentTariff) {
            <tariff-check-donut [tariff]="currentTariff"></tariff-check-donut>
            } @else {
            <tariff-check-donut></tariff-check-donut>
            } @if (currentTariff) {
            <div class="logo-container">
              @if (isCurrentLogoLoaded) {
              <img
                [src]="currentCompanyLogoToShow"
                class="logo"
                alt="currentCompanyLogo"
              />
              } @else {
              <i
                class="fa-regular fa-shield-quartered fa-2x theme-color-primary"
                aria-hidden="true"
              ></i>
              }
            </div>
            } @else {
            <div class="button-container">
              <button
                mat-flat-button
                (click)="
                  showTariffDialog(
                    currentProductType,
                    currentTariff,
                    offerTariff,
                    false
                  )
                "
                data-cy="current-button"
              >
                Auswählen
              </button>
            </div>
            } @if (currentTariff) {
            <mat-card-content>
              <p class="company" data-cy="company">
                {{ currentTariff.company.name! | removeAG }}
              </p>
              <div
                class="tariff-name theme-color-primary"
                data-cy="current-tariff"
                (click)="
                  showTariffDialog(
                    currentProductType,
                    currentTariff,
                    offerTariff,
                    false
                  )
                "
              >
                {{ currentTariff.name }},
                {{ currentTariff.terms }}
                <mat-icon
                  class="theme-color-primary"
                  fontSet="far"
                  fontIcon="fa-edit"
                ></mat-icon>
              </div>
              @if (currentTariff.variant) {
              <div data-cy="variant">
                {{ currentTariff.variant }}
              </div>
              } @if (currentTariff.additional_information) {
              <div data-cy="additional_information">
                <button
                  class="warn"
                  mat-button
                  (click)="
                    showInfoDialog(
                      currentTariff.additional_information,
                      'Wichtige Zusatzinformation',
                      'circle-exclamation',
                      'warn'
                    )
                  "
                >
                  Wichtige Zusatzinformation
                </button>
              </div>
              }
            </mat-card-content>
            }
          </mat-card>
          <mat-card class="flat-card flex-grow-50">
            <mat-card-title>Optimierung</mat-card-title>
            @if (offerTariff) {
            <tariff-check-donut [tariff]="offerTariff"></tariff-check-donut>
            } @else {
            <tariff-check-donut></tariff-check-donut>
            } @if (offerTariff) {
            <div class="logo-container logo-offer">
              @if (isOfferLogoLoaded) {
              <img
                [src]="offerCompanyLogoToShow"
                class="logo"
                alt="offerCompanyLogo"
              />
              } @else {
              <i
                class="fa-regular fa-shield-quartered fa-2x theme-color-primary"
                aria-hidden="true"
              ></i>
              }
            </div>
            } @if (offerTariff) {
            <mat-card-content>
              <p class="company" data-cy="company">
                {{ offerTariff.company.name! | removeAG }}
              </p>
              <div
                class="tariff-name theme-color-primary"
                data-cy="offer-tariff"
                (click)="
                  showTariffDialog(
                    currentProductType,
                    currentTariff,
                    offerTariff,
                    true
                  )
                "
              >
                {{ offerTariff.name }},
                {{ offerTariff.terms }}
                <mat-icon
                  class="theme-color-primary"
                  fontSet="far"
                  fontIcon="fa-edit"
                ></mat-icon>
              </div>
              @if (offerTariff.variant) {
              <div data-cy="variant">
                {{ offerTariff.variant }}
              </div>
              } @if (offerTariff.additional_information) {
              <div data-cy="additional_information">
                <button
                  mat-button
                  class="warn"
                  (click)="
                    showInfoDialog(
                      offerTariff.additional_information,
                      'Wichtige Zusatzinformation',
                      'circle-exclamation',
                      'warn'
                    )
                  "
                >
                  Wichtige Zusatzinformation
                </button>
              </div>
              }
            </mat-card-content>
            } @else {
            <div class="button-container">
              <button
                mat-flat-button
                (click)="
                  showTariffDialog(
                    currentProductType,
                    currentTariff,
                    offerTariff,
                    true
                  )
                "
                data-cy="offer-button"
              >
                Auswählen
              </button>
            </div>
            }
          </mat-card>
        </div>
      </mat-card>
      <mat-card class="card-category">
        <mat-card-content>
          <mat-accordion multi hideToggle="false">
            @for (category of categories; track category.id; let i = $index) {
            <div>
              @if (category.isAdditionalCriterionCategory == false) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="flex-row flex-grow">
                    @if (currentTariff) {
                    <div
                      [ngClass]="
                        getCategoryScore(currentTariff, category.name) ==
                        category.criterionCount
                          ? 'success'
                          : 'fail'
                      "
                      class="category-score flex-grow justify-start-xs"
                    >
                      {{ getCategoryScore(currentTariff, category.name) }}/{{
                        category.criterionCount
                      }}
                    </div>
                    } @else {
                    <div class="category-score flex-grow justify-start-xs">
                      &nbsp;
                    </div>
                    }
                    <div class="category-name flex-grow">
                      {{ category.name }}
                    </div>
                    @if (offerTariff) {
                    <div
                      [ngClass]="
                        getCategoryScore(offerTariff, category.name) ==
                        category.criterionCount
                          ? 'success'
                          : 'fail'
                      "
                      class="category-score flex-grow justify-end-xs"
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;{{
                        getCategoryScore(offerTariff, category.name)
                      }}/{{ category.criterionCount }}
                    </div>
                    } @else {
                    <div class="category-score flex-grow justify-start-xs">
                      &nbsp;
                    </div>
                    }
                  </div>
                </mat-expansion-panel-header>
                @for (criteria of category.criterions; track criteria.id; let j
                = $index) {
                <div>
                  <mat-card class="flat-card">
                    <div class="criterion-name">
                      {{ criteria.name }}
                      @if (criteria.value !== ' ') {
                      <span>-</span>
                      }
                      {{ criteria.value }}
                      <span class="info-icon">
                        <mat-icon
                          mat-list-icon
                          fontSet="fas"
                          fontIcon="fa-info-circle"
                          class="theme-color-primary info-circle"
                          [attr.data-cy]="'icon-crit-' + i + j"
                          (click)="
                            showCriterionInfo(criteria.name, criteria.aboutLong)
                          "
                        ></mat-icon>
                      </span>
                    </div>
                    @if (currentTariff || offerTariff) {
                    <mat-card-content class="flex-row">
                      @if (currentTariff) {
                      <div
                        [ngClass]="
                          isCriterionSatisfied(currentTariff, category.name, j)
                            ? 'success'
                            : 'fail'
                        "
                        class="criterion criterion-left flex-grow-50"
                      >
                        <i
                          [ngClass]="
                            isCriterionSatisfied(
                              currentTariff,
                              category.name,
                              j
                            )
                              ? 'fa-check text-success'
                              : 'fa-times text-error'
                          "
                          class="fas"
                          aria-hidden="true"
                        ></i>

                        {{ getCriterionValue(currentTariff, category.name, j) }}
                      </div>
                      } @else {
                      <div class="criterion criterion-left flex-grow-50"></div>
                      } @if (offerTariff) {
                      <div
                        class="criterion criterion-right flex-grow-50"
                        [ngClass]="
                          isCriterionSatisfied(offerTariff, category.name, j)
                            ? 'success'
                            : 'fail'
                        "
                      >
                        <i
                          [ngClass]="
                            isCriterionSatisfied(offerTariff, category.name, j)
                              ? 'fa-check text-success'
                              : 'fa-times text-error'
                          "
                          class="fas"
                          aria-hidden="true"
                        ></i>
                        {{ getCriterionValue(offerTariff, category.name, j) }}
                      </div>
                      }
                    </mat-card-content>
                    }
                  </mat-card>
                </div>
                }
              </mat-expansion-panel>
              }
            </div>
            }
          </mat-accordion>
        </mat-card-content>
      </mat-card>

      <mat-card class="card-category">
        <mat-card-title class="extra-criterion">
          Zusatzkriterien
          <mat-icon
            mat-list-icon
            fontSet="fas"
            fontIcon="fa-info-circle"
            class="theme-color-primary info-circle"
            (click)="
              showInfoDialog(
                'Die Zusatzkriterien ermöglichen einen umfassenden Tarifvergleich. Diese Kriterien fließen nicht in die Bewertung nach Verbraucherschutz ein, sondern dienen der zusätzlichen Information.',
                'Zusatzkriterien',
                'circle-info',
                'primary'
              )
            "
          ></mat-icon
        ></mat-card-title>
        <mat-card-content>
          <mat-accordion multi>
            @for (category of categories; track category.id; let i = $index) {
            <div>
              @if (category.isAdditionalCriterionCategory == true) {
              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <div class="category-score hidden flex-grow-50">not</div>
                  <div class="category-name">
                    {{ category.name! | removeExtraCrit }}
                  </div>
                </mat-expansion-panel-header>
                @for (criteria of category.criterions; track criteria.id; let j
                = $index) {
                <div>
                  <mat-card class="flat-card">
                    <div class="criterion-name">
                      {{ criteria.name }}
                      <span class="info-icon">
                        <mat-icon
                          mat-list-icon
                          fontSet="fas"
                          class="theme-color-primary info-circle"
                          fontIcon="fa-info-circle"
                          (click)="
                            showCriterionInfo(criteria.name, criteria.aboutLong)
                          "
                        ></mat-icon>
                      </span>
                    </div>
                    <mat-card-content class="flex-row">
                      @if (currentTariff) {
                      <div class="criterion criterion-left flex-grow-50">
                        {{ getCriterionValue(currentTariff, category.name, j) }}
                      </div>
                      } @else {
                      <div class="flex-grow-50"></div>
                      } @if (offerTariff) {
                      <div class="criterion criterion-right flex-grow-50">
                        {{ getCriterionValue(offerTariff, category.name, j) }}
                      </div>
                      } @else {
                      <div class="flex-grow-50"></div>
                      }
                    </mat-card-content>
                  </mat-card>
                </div>
                }
              </mat-expansion-panel>
              }
            </div>
            }
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
    } @else {
    <div class="card-container">
      <mat-card>
        <div>Fehler</div>
      </mat-card>
    </div>
    }
  </main>
</mat-drawer-container>
