import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from 'apps/tariff-check/src/app/home/home.component';
import { SsoComponent } from 'apps/tariff-check/src/app/sso/sso.component';
import { ImpersonateComponent } from 'apps/tariff-check/src/app/impersonate/impersonate.component';
import { PasswordComponent } from 'apps/tariff-check/src/app/password/password.component';
import { ComplianceComponent } from 'apps/tariff-check/src/app/compliance/compliance.component';
import { ProductSelectComponent } from 'apps/tariff-check/src/app/product-select/product-select.component';
import { AccountComponent } from 'apps/tariff-check/src/app/account/account.component';
import { TariffSelectComponent } from 'apps/tariff-check/src/app/tariff-select/tariff-select.component';
import { CriteriaComponent } from 'apps/tariff-check/src/app/criteria/criteria.component';
import { TariffCheckComponent } from 'apps/tariff-check/src/app/tariff-check/tariff-check.component';
import { PrintComponent } from 'apps/tariff-check/src/app/print/print.component';
import { AuthGuardService } from '@smart-check/shared/ui';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [],
    component: HomeComponent,
  },
  {
    path: 'enter/:ssoToken',
    canActivate: [],
    component: SsoComponent,
  },
  {
    path: 'impersonate/:token',
    canActivate: [],
    component: ImpersonateComponent,
  },
  {
    path: 'reset/:csrfToken',
    canActivate: [],
    component: PasswordComponent,
  },
  {
    path: 'compliance',
    canActivate: [AuthGuardService],
    component: ComplianceComponent,
  },
  {
    path: 'productSelect',
    canActivate: [AuthGuardService],
    component: ProductSelectComponent,
  },
  {
    path: 'tariffSelect/:product',
    canActivate: [AuthGuardService],
    component: TariffSelectComponent,
  },
  {
    path: 'tariffCheck/:product/:currentTariff',
    canActivate: [AuthGuardService],
    component: TariffCheckComponent,
  },
  {
    path: 'tariffCheck/:product/:currentTariff/:offerTariff',
    canActivate: [AuthGuardService],
    component: TariffCheckComponent,
  },
  {
    path: 'verwaltung',
    canActivate: [AuthGuardService],
    component: AccountComponent,
  },
  {
    path: 'criterionList',
    canActivate: [AuthGuardService],
    component: CriteriaComponent,
  },
  {
    path: 'pdf/:product/:currentTariff',
    canActivate: [AuthGuardService],
    component: PrintComponent,
  },
  {
    path: 'pdf/:product/:currentTariff/:offerTariff',
    canActivate: [AuthGuardService],
    component: PrintComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
