import { UserDetails } from '@smart-check/feature-comparison';

const mockUserDetails: UserDetails = {
  gender: 'm',
  firstName: 'Bud',
  lastName: 'Spencer',
  email: 'bud@spencer.de',
  phone: '',
  position: '',
  broker_no: '',
  companyName: '',
  street: 'Sachsenring',
  streetNumber: '23',
  zip: '50677',
  city: 'Köln',
  acceptedGdpr: 1,
  wantsWerbung: 1,
  anonymous: false,
};

const mockUserDetailsWithExclusiveTariffe: UserDetails = {
  gender: 'm',
  firstName: 'Bud',
  lastName: 'Spencer',
  email: 'bud@spencer.de',
  phone: '',
  position: '',
  broker_no: '',
  companyName: '',
  street: 'Sachsenring',
  streetNumber: '23',
  zip: '50677',
  city: 'Köln',
  acceptedGdpr: 1,
  wantsWerbung: 1,
  exclusiveTariffe: [{ productTypeId: 2, exclusiveTariffId: 25949 }],
  anonymous: false,
};

const mockUserDetailsAnonymous: UserDetails = {
  gender: 'm',
  firstName: 'Bud',
  lastName: 'Spencer',
  email: 'bud@spencer.de',
  phone: '',
  position: '',
  broker_no: '',
  companyName: '',
  street: 'Sachsenring',
  streetNumber: '23',
  zip: '50677',
  city: 'Köln',
  acceptedGdpr: 1,
  wantsWerbung: 1,
  anonymous: true,
};

export {
  mockUserDetails,
  mockUserDetailsWithExclusiveTariffe,
  mockUserDetailsAnonymous,
};
