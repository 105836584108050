import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PosthogService } from './posthog.service';

@Injectable({
  providedIn: 'root',
})
export class AutomatedTrackingService {
  private previousUrl: string;
  private previousTimestamp: Date;

  constructor(
    private readonly router: Router,
    private readonly posthogService: PosthogService
  ) {}

  public async init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.posthogService.init(
        'phc_GQeQwxdzEQnIM1gRd2hKRIfWvL7s5HESe8vVjThijvR',
        {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          api_host: 'https://eu.posthog.com',
          persistence: 'localStorage',
          loaded: () => {
            this.initRouteTracking();
            resolve();
          },
        }
      );
    });
  }

  private initRouteTracking(): void {
    window.addEventListener('unload', () => this.captureCustomPageleave());

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.captureCustomPageleave();
        this.posthogService.capturePageview();
      });
  }

  private captureCustomPageleave() {
    if (this.previousTimestamp) {
      const durationInSeconds =
        (Date.now() - this.previousTimestamp.getTime()) / 1000;
      this.posthogService.capture('pageleave', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        $current_url: this.previousUrl,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        duration_in_seconds: durationInSeconds,
      });
    }
    this.previousUrl = window.location.href;
    this.previousTimestamp = new Date();
  }
}
