import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, Login, ThemeService, User } from '@smart-check/shared/ui';
import { MatDialog } from '@angular/material/dialog';
import { PosthogService } from '@smart-check/shared/analytics';
import { DialogCriterionComponent } from '../dialog/dialog-criterion/dialog-criterion.component';
import { DialogTestComponent } from '../dialog/dialog-test/dialog-test.component';
import { DialogPasswordComponent } from '../dialog/dialog-password/dialog-password.component';

@Component({
  selector: 'smart-check-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public currentYear: number = new Date().getFullYear();
  public loginForm: FormGroup;
  public login: Login;
  public responseMessage: string;
  public userGroupId: number = -1;
  public logoFileName: string;
  public user: User;
  public failLogin: number = 0;
  public passwordHidden: boolean = true;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private themeService: ThemeService,
    private posthogService: PosthogService
  ) {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.setTheme(this.userGroupId);
  }

  scrollToElement(element: HTMLElement, event: any): void {
    event.stopPropagation();
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  setTheme(userGroupId: number) {
    this.themeService.setTheme(userGroupId);
    this.themeService.setFavicon(userGroupId);
  }

  showCriterionInfo(name: string, aboutLong: string) {
    this.dialog.open(DialogCriterionComponent, {
      data: { name, aboutLong },
    });
  }

  showTestAccount() {
    this.dialog.open(DialogTestComponent);
  }

  showForgotPassword() {
    this.dialog.open(DialogPasswordComponent);
  }

  onSubmit() {
    this.login = new Login(
      this.loginForm.value.userName,
      this.loginForm.value.password
    );

    if (this.loginForm.valid) {
      this.authService.getIdentity(this.login).subscribe({
        next: (response: User) => {
          this.user = response;
        },
        complete: () => {
          if (this.user.result === 0) {
            localStorage.setItem('user', JSON.stringify(this.user.currentUser));
            const sessionUser = JSON.parse(localStorage.getItem('user')!);
            localStorage.setItem('scheme', sessionUser.scheme.colorScheme);
            this.posthogService.identifyUser(this.user.currentUser);
            if (this.user.currentUser.acceptedGdpr === 1) {
              this.router.navigate(['productSelect']);
            } else {
              this.router.navigate(['compliance']);
            }
          } else {
            this.loginForm.get('userName')?.setErrors({ incorrect: true });
            this.loginForm.get('password')?.setErrors({ incorrect: true });
            this.responseMessage =
              'Die Anmeldung ist fehlgeschlagen. Bitte prüfen Sie Ihre Angaben';
          }

          if (this.user.result === 5) {
            this.responseMessage =
              'Aufgrund zu vieler erfolgloser Versuche wird der Login für ' +
              this.user.resultText +
              ' Minuten gesperrt.';
          }
        },
      });
    } else {
      this.loginForm.get('userName')?.setErrors({ incorrect: true });
      this.loginForm.get('password')?.setErrors({ incorrect: true });
      this.responseMessage =
        'Die Anmeldung ist fehlgeschlagen. Bitte prüfen Sie Ihre Angaben';
    }
  }
}
