import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import {
  TariffService,
  CompaniesParams,
  YearsParams,
  TariffByYearParams,
  TariffVariantsParams,
  ProductType,
  Company,
  Tariff,
  Variant,
  Sidenav,
  TariffSelection,
  UserService,
  UserDetails,
  ExclusiveTariff,
  TariffParams,
} from '@smart-check/feature-comparison';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'form-tariff',
  templateUrl: './form-tariff.component.html',
  styleUrls: ['./form-tariff.component.scss'],
})
export class FormTariffComponent implements OnInit {
  public selectTariffForm: FormGroup = this.formBuilder.group({});
  public sideNavigation: Sidenav = {
    id: 0,
    title: '',
  };
  public currentProductType: ProductType;
  public currentCompany: string;
  public currentYear: string;
  public currentTariffVariant: string;
  public companiesParams: CompaniesParams;
  public companies: Array<Company>;
  public company: Company = {
    id: 0,
  };
  public years: Array<string>;
  public tariffs: Array<Tariff>;
  public tariffVariants: Array<Variant> | null;
  public selectedTariff: Tariff | Variant;
  public hasTags: boolean = false;
  public filteredCompaniesOptions: Observable<any[]> | undefined;
  public filteredTariffsOptions: Observable<any[]> | undefined;
  public filteredVariantsOptions: Observable<Variant[] | undefined> | undefined;
  public isDialog: boolean;
  public isOnlyTopTariff: boolean = false;
  public hasTopTariff: boolean = true;
  public userDetails: UserDetails;
  @ViewChild('btnSubmit') buttonSubmit: MatButton;
  @Input() data: TariffSelection;
  @Output() callToggleSidenav = new EventEmitter();
  constructor(
    private tariffService: TariffService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.selectTariffForm = new FormGroup({
      companyControl: new FormControl('', [Validators.required]),
      yearControl: new FormControl(''),
      tariffControl: new FormControl('', [Validators.required]),
      tariffVariantControl: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.router.url.indexOf('/tariffSelect') > -1) {
      this.isDialog = false;
    } else {
      this.isDialog = true;
    }

    this.fillCompanyForm(this.data.currentProductType.id, false);

    this.userService.getUserDetails().subscribe({
      next: (response: UserDetails) => {
        this.userDetails = response;
        this.getExclusiveTariff(
          this.data.currentProductType.id,
          this.userDetails
        );
      },
    });

    // change current tariff
    if (this.data.currentTariff && this.data.isOffer === false) {
      this.company = this.data.currentTariff?.company;

      this.selectTariffForm
        .get('tariffControl')
        ?.setValue(
          this.data.currentTariff?.name + ', ' + this.data.currentTariff?.terms
        );

      this.selectTariffForm
        .get('companyControl')
        ?.setValue(this.data.currentTariff.company?.short_name);

      this.selectTariffForm
        .get('tariffVariantControl')
        ?.setValue(this.data.currentTariff?.variant);

      if (this.data.currentTariff?.variant === '') {
        this.selectTariffForm
          .get('tariffVariantControl')
          ?.setValue('Ohne Baustein');
      } else {
        this.selectTariffForm
          .get('tariffVariantControl')
          ?.setValue(this.data.currentTariff?.variant);
      }

      this.selectedTariff = this.data.currentTariff;

      this.fillYearForm(
        this.data.currentProductType.reference_tariff_id,
        this.data.currentTariff.company?.id,
        false
      );

      this.fillTariffForm(
        this.data.currentProductType.reference_tariff_id,
        this.data.currentTariff.company?.id,
        '-1',
        false
      );

      this.fillVariantForm(this.data.currentTariff?.id);
    }

    // change offer tariff
    if (this.data.offerTariff && this.data.isOffer === true) {
      this.company = this.data.offerTariff?.company;
      this.selectTariffForm
        .get('tariffControl')
        ?.setValue(
          this.data.offerTariff?.name + ', ' + this.data.offerTariff?.terms
        );

      this.selectTariffForm
        .get('companyControl')
        ?.setValue(this.data.offerTariff.company?.short_name);

      if (this.data.offerTariff?.variant === '') {
        this.selectTariffForm
          .get('tariffVariantControl')
          ?.setValue('Ohne Baustein');
      } else {
        this.selectTariffForm
          .get('tariffVariantControl')
          ?.setValue(this.data.offerTariff?.variant);
      }

      this.selectedTariff = this.data.offerTariff;

      this.fillYearForm(
        this.data.currentProductType.reference_tariff_id,
        this.data.offerTariff.company?.id,
        true
      );

      this.fillTariffForm(
        this.data.currentProductType.reference_tariff_id,
        this.data.offerTariff.company?.id,
        '-1',
        true
      );

      this.fillVariantForm(this.data.offerTariff?.id);
    }
  }

  fillCompanyForm(idOrSlug: number, topFlag: boolean) {
    const companiesParams = {
      idOrSlug: idOrSlug,
      topFlag: topFlag,
    };

    this.tariffService.getCompanies(companiesParams).subscribe({
      next: (response: Array<Company>) => {
        this.companies = response;
      },
      complete: () => {
        this.checkTopTariff(this.companies, topFlag);
        this.filterCompanies();
        this.filterTariffs();
        this.selectTariffForm.get('companyControl')?.enable();
      },
    });
  }

  fillYearForm(refTariffId: number, companyId: number, onlyActive: boolean) {
    const yearsParams: YearsParams = {
      refTariffId: refTariffId,
      companyId: companyId,
      onlyActive: onlyActive,
    };
    this.tariffService.getYears(yearsParams).subscribe({
      next: (response: Array<string>) => {
        this.years = response;
      },
      complete: () => {
        this.selectTariffForm.get('yearControl')?.setValue(this.years[0]);
      },
    });
  }

  fillTariffForm(
    referenceTariffId: number,
    companyId: number,
    year: string,
    onlyActive: boolean
  ) {
    const tariffByYearParams: TariffByYearParams = {
      referenceTariffId: referenceTariffId,
      companyId: companyId,
      year: year,
      onlyActive: onlyActive,
    };
    this.tariffService.getTariffByYear(tariffByYearParams).subscribe({
      next: (response: Array<Tariff>) => {
        this.tariffs = response.filter(
          (tariff: Tariff) => tariff.show_in_comparison
        );
      },
      complete: () => {
        this.filterTariffs();
      },
    });
  }

  fillVariantForm(idOrSlug: number) {
    const tariffVariantsParams: TariffVariantsParams = {
      idOrSlug: idOrSlug,
    };
    this.tariffService.getTariffVariants(tariffVariantsParams).subscribe({
      next: (response: Array<Variant>) => {
        this.showTagsField(response);
      },
      complete: () => {
        this.selectTariffForm.get('tariffVariantControl')?.enable();
      },
    });
  }

  selectCompany(company: Company, event: any) {
    if (event.isUserInput) {
      this.company = company;
      const tariffByYearParams: TariffByYearParams = {
        referenceTariffId: this.data.currentProductType.reference_tariff_id,
        companyId: company.id,
        year: '-1',
        onlyActive: this.data.isOffer,
      };
      this.resetTariffControl();
      this.resetTariffVariantControl();
      this.filterCompanies();
      this.fillYearForm(
        this.data.currentProductType.reference_tariff_id,
        company.id,
        this.data.isOffer
      );

      if (this.isOnlyTopTariff) {
        this.tariffService.getTopTariffs(tariffByYearParams).subscribe({
          next: (response: Array<Tariff>) => {
            this.tariffs = response.filter(
              (tariff: Tariff) => tariff.show_in_comparison
            );
          },
          complete: () => {
            this.selectTariffForm.get('tariffControl')?.enable();
          },
        });
      } else {
        this.tariffService.getTariffByYear(tariffByYearParams).subscribe({
          next: (response: Array<Tariff>) => {
            this.tariffs = response.filter(
              (tariff: Tariff) => tariff.show_in_comparison
            );
          },
          complete: () => {
            this.selectTariffForm.get('tariffControl')?.enable();
          },
        });
      }
    }
  }

  selectYear(year: string, event: any) {
    if (event.isUserInput) {
      this.fillTariffForm(
        this.data.currentProductType.reference_tariff_id,
        this.company.id,
        year,
        this.data.isOffer
      );
    }
  }

  selectTariff(tariff: Tariff, event: any) {
    if (event.isUserInput) {
      this.resetTariffVariantControl();
      this.filterTariffs();
      this.selectedTariff = tariff;
      if (this.selectedTariff.is_baustein_tariff && !this.isOnlyTopTariff) {
        this.filterVariantTariffs();
        this.fillVariantForm(tariff.id);
      }
    }
  }

  selectTariffVariant(tariffVariant: Variant, event: any) {
    if (event.isUserInput) {
      this.selectedTariff = tariffVariant;
    }
  }

  showTagsField(tariffVariants: Array<Variant>) {
    if (tariffVariants[0].tags) {
      this.tariffVariants = tariffVariants;
    } else {
      this.selectTariffForm.get('tariffVariantControl')?.setErrors(null);
    }
  }

  toggleSidenavForm(id: number) {
    this.callToggleSidenav.emit(id);
  }

  displayFn(company: Company): Company | string {
    return company?.short_name ?? '';
  }

  getTopTariff(event: any) {
    this.isOnlyTopTariff = event.checked;
    this.selectTariffForm.get('companyControl')?.setValue('');
    this.resetCompanyControl();
    this.resetTariffControl();
    this.resetTariffVariantControl();
    this.fillCompanyForm(
      this.data.currentProductType.id,
      this.isOnlyTopTariff
    );
  }

  tariffAnalyse(selectedTariffId: number) {
    if (this.data.isOffer && this.data.currentTariff) {
      // has current tariff, change offer tariff
      this.router.navigate([
        '/tariffCheck',
        this.data.currentProductType.id,
        this.data.currentTariff.id,
        selectedTariffId,
      ]);
    } else if (!this.data.isOffer && this.data.offerTariff) {
      // change current tariff, has offer tariff
      this.router.navigate([
        '/tariffCheck',
        this.data.currentProductType.id,
        selectedTariffId,
        this.data.offerTariff.id,
      ]);
    } else if (this.data.isOffer && !this.data.currentTariff) {
      // empty current tariff, change offer tariff
      this.router.navigate([
        '/tariffCheck',
        this.data.currentProductType.id,
        'null',
        selectedTariffId,
      ]);
    } else {
      // change current tariff, empty offer tariff
      this.router.navigate([
        '/tariffCheck',
        this.data.currentProductType.id,
        selectedTariffId,
      ]);
    }

    if (this.isDialog) {
      this.dialog.closeAll();
    }
  }

  filterCompanies() {
    this.filteredCompaniesOptions = this.selectTariffForm
      .get('companyControl')
      ?.valueChanges.pipe(
        startWith(''),
        map((value: Company) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name
            ? this.filterCompany(name)
            : this.companies.slice();
        })
      );
  }

  filterCompany(name: string): Array<Company> {
    const filterValue = name.toLowerCase();
    return this.companies.filter(
      (option: any) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.short_name.toLowerCase().includes(filterValue)
    );
  }

  filterTariffs() {
    this.filteredTariffsOptions = this.selectTariffForm
      .get('tariffControl')
      ?.valueChanges.pipe(
        startWith(''),
        map((value: Tariff) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name
            ? this.filterTariff(name)
            : this.tariffs?.slice();
        })
      );
  }

  filterTariff(name: string): Array<Tariff> {
    const filterValue = name.toLowerCase();

    return this.tariffs.filter(
      (option: any) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.terms.toLowerCase().includes(filterValue)
    );
  }

  filterVariantTariffs() {
    this.filteredVariantsOptions = this.selectTariffForm
      .get('tariffVariantControl')
      ?.valueChanges.pipe(
        startWith(''),
        map((value: Variant) => {
          const name = typeof value === 'string' ? value : value?.tariffName;
          return name
            ? this.filterVariantTariff(name)
            : this.tariffVariants?.slice();
        })
      );
  }

  filterVariantTariff(name: string): Array<Variant> | undefined {
    const filterValue = name.toLowerCase();
    return this.tariffVariants?.filter((option: any) =>
      option.tags.toString().toLowerCase().includes(filterValue)
    );
  }

  resetCompanyControl() {
    this.selectTariffForm.get('companyControl')?.reset();
    this.selectTariffForm.get('companyControl')?.disable();
    this.companies = [];
  }

  resetTariffControl() {
    this.selectTariffForm.get('tariffControl')?.reset();
    this.selectTariffForm.get('tariffControl')?.disable();
    this.tariffs = [];
  }

  resetTariffVariantControl() {
    this.selectTariffForm.get('tariffVariantControl')?.reset();
    this.selectTariffForm.get('tariffVariantControl')?.disable();
    this.tariffVariants = null;
  }

  onEnter(event: any) {
    event.preventDefault();
    this.buttonSubmit.focus();
  }

  onNoClick(): void {
    this.dialog.closeAll();
  }

  checkTopTariff(companies: Array<Company>, topFlag: boolean): void {
    if (companies.length < 1 && topFlag === true) {
      this.hasTopTariff = false;
    } else {
      this.hasTopTariff = true;
    }
  }

  getExclusiveTariff(
    currentProductType: number,
    userDetails: UserDetails
  ): void {
    if (userDetails.exclusiveTariffe!.length > 0 && !this.data.offerTariff) {
      const exclusiveTariffs: any = userDetails.exclusiveTariffe;
      const exclusiveTariff = Array.isArray(exclusiveTariffs)
        ? exclusiveTariffs.find(
            (exclusivetariff: ExclusiveTariff) =>
              exclusivetariff.productTypeId === currentProductType
          )
        : null;

      if (exclusiveTariff) {
        const tariffParams: TariffParams = {
          idOrSlug: exclusiveTariff.exclusiveTariffId,
          withCrit: false,
          onlyActive: false,
        };

        this.tariffService.getSingleTariff(tariffParams).subscribe({
          next: (response: Tariff) => {
            this.data.offerTariff = response;
          },
        });
      }
    }
  }
}
