import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';
import { UserDetails } from '@smart-check/feature-comparison';
import {
  CurrentUser,
  APP_CONFIG,
  UtilsService,
  User,
} from '@smart-check/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: CurrentUser = JSON.parse(localStorage.getItem('user')!);

  private url: string;
  constructor(private http: HttpClient, private utilsService: UtilsService) {
    this.url = APP_CONFIG.csApi;
  }

  checkPassword(password: string): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    const formData: FormData = new FormData();
    formData.append('password', password);
    return this.http.post(`${this.url}/ajax/CheckPassword`, formData, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  changePassword(newPw: string, oldPw: string): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    const formData: FormData = new FormData();
    formData.append('newPw', newPw);
    formData.append('oldPw', oldPw);
    return this.http.post(`${this.url}/ajax/changePassword`, formData, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  requestPassword(email: string): Observable<Object> {
    const formData: FormData = new FormData();
    formData.append('email', email);
    return this.http.post(`${this.url}/requestPassword`, formData, {
      responseType: 'json',
      withCredentials: false,
    });
  }

  checkToken(csrf1: string): Observable<User> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: csrf1,
    }).set('content-type', 'application/json; charset=utf-8');
    return this.http.post<User>(`${this.url}/checkToken`, null, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  resetPassword(
    newPw: string,
    csrf1: string,
    csrf2: string
  ): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: csrf2,
    });
    const formData: FormData = new FormData();
    formData.append('csrf1', csrf1);
    formData.append('newPw', newPw);
    return this.http.post(`${this.url}/resetPassword`, formData, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  getHasSeenInfo(slug: string, toogle: boolean): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<string>(
      `${this.url}/ajax/GetHasSeenInfo/${slug}/${toogle}`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getConsumption(fromDate: string, toDate: string): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post(
      `${this.url}/ajax/GetConsumption/${fromDate}/${toDate}`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getConsumptionYearly(
    year: number
  ): Observable<Array<{ month: number; checks: number }>> {
    let res: Array<{ month: number; checks: number }> = [];
    let subject = new Subject<Array<{ month: number; checks: number }>>();
    for (let index = 0; index < 12; index++) {
      this.getConsumptionMonthly(year, index + 1)
        .pipe(
          map((response) => {
            let checks = this.sumChecks(Object.values(response));
            let consumptionObject = {
              month: index + 1,
              checks,
            };
            res.push(consumptionObject);
            res = this.utilsService.sortArrayOfObjects(res, 'month', 'desc');
            return res;
          })
        )
        .subscribe((response) => {
          subject.next(response);
        });
    }
    return subject.asObservable();
  }

  sumChecks(checks: Array<number>): number {
    let sum = 0;
    for (let key in checks) {
      sum += checks[key];
    }
    return sum;
  }

  getConsumptionMonthly(year: number, month: number): Observable<Object> {
    let leapYear: string = '28';
    if (this.isLeapYear(year)) {
      leapYear = '29';
    }
    switch (month) {
      case 1:
        return this.getConsumption(`${year}-01-01`, `${year}-01-31`);
      case 2:
        return this.getConsumption(`${year}-02-01`, `${year}-02-${leapYear}`);
      case 3:
        return this.getConsumption(`${year}-03-01`, `${year}-03-31`);
      case 4:
        return this.getConsumption(`${year}-04-01`, `${year}-04-30`);
      case 5:
        return this.getConsumption(`${year}-05-01`, `${year}-05-31`);
      case 6:
        return this.getConsumption(`${year}-06-01`, `${year}-06-30`);
      case 7:
        return this.getConsumption(`${year}-07-01`, `${year}-07-31`);
      case 8:
        return this.getConsumption(`${year}-08-01`, `${year}-08-31`);
      case 9:
        return this.getConsumption(`${year}-09-01`, `${year}-09-30`);
      case 10:
        return this.getConsumption(`${year}-10-01`, `${year}-10-31`);
      case 11:
        return this.getConsumption(`${year}-11-01`, `${year}-11-30`);
      case 12:
        return this.getConsumption(`${year}-12-01`, `${year}-12-31`);
      default:
        return this.getConsumption('0000-00-00', '9999-12-31');
    }
  }

  isLeapYear(year: number): boolean {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
  }

  getUserDetails(): Observable<UserDetails> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<UserDetails>(
      `${this.url}/ajax/GetUserDetails`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  setUserDetails(userDetails: UserDetails): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post(`${this.url}/ajax/SetUserDetails`, userDetails, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  setGdprAds(userDetails: UserDetails): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post(`${this.url}/ajax/SetGdprAds`, userDetails, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  getUsergroupLogo(): Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Blob>(`${this.url}/ajax/GetUsergroupLogo`, null, {
      headers,
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }

  getUserLogo(): Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Blob>(`${this.url}/ajax/GetUserLogo`, null, {
      headers,
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }

  getTeamLogo(): Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Blob>(`${this.url}/ajax/GetTeamLogo`, null, {
      headers,
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }

  uploadLogo(file: File): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Object>(`${this.url}/UploadLogo`, formData, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  removeUserLogo(): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Object>(`${this.url}/RemoveUserLogo`, null, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }
}
