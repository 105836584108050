import { Company } from '@smart-check/feature-comparison';

const mockCompany1: Company = {
  id: 10,
  slug: 'aachenmunchener-versicherung-ag',
  is_active: false,
  name: 'AachenMünchener Versicherung AG',
  short_name: 'www.amv.de',
  web: null,
  product_type_ids: null,
  import_id: 2,
  import_id_neu: 421,
  uuid: 'a6ed4934-f7a5-4212-9f68-d3446d101eed',
  fbb_id: '603',
  broker_id: '32455',
  bafin_id: '5342',
  logo: '3.png',
  logoThumb: 'Aachen Münchener Logo.png',
  address: {
    name: null,
    street: 'Aureliusstraße 2',
    street_number: '',
    zipcode: '52064',
    city: 'Aachen',
    state: null,
    country: 'Deutschland',
    telefone1: null,
    telefone2: null,
    email: null,
  },
  contacts: null,
  currentCompany_id: 5342,
};

const mockCompany2: Company = {
  id: 5952,
  slug: 'adac-versicherung-ag',
  is_active: true,
  name: 'ADAC Versicherung AG',
  short_name: 'www.adac.de',
  web: null,
  product_type_ids: null,
  import_id: null,
  import_id_neu: null,
  uuid: '1bac34f4-7886-4697-9309-415d6e7231f5',
  fbb_id: null,
  broker_id: null,
  bafin_id: null,
  logo: null,
  logoThumb: null,
  address: {
    name: null,
    street: 'Hansastraße',
    street_number: '19',
    zipcode: '80686',
    city: 'München',
    state: null,
    country: '',
    telefone1: null,
    telefone2: null,
    email: null,
  },
  contacts: null,
  currentCompany_id: null,
};

const mockCompany3: Company = {
  id: 2,
  slug: 'adcuri-gmbh',
  is_active: true,
  name: 'ADCURI GmbH',
  short_name: 'www.adcuri.de',
  web: null,
  product_type_ids: null,
  import_id: 4699,
  import_id_neu: 585,
  uuid: 'e182febc-4f43-46ff-aec1-5b8cd6a333ba',
  fbb_id: '2352',
  broker_id: '',
  bafin_id: '8993',
  logo: 'adcuri.jpg',
  logoThumb: 'adcuri_thumb.jpg',
  address: {
    name: null,
    street: '',
    street_number: '',
    zipcode: '',
    city: '',
    state: null,
    country: '',
    telefone1: null,
    telefone2: null,
    email: null,
  },
  contacts: null,
  currentCompany_id: 8993,
};

const mockCompanyArray: Company[] = [mockCompany1, mockCompany2];

export { mockCompany1, mockCompanyArray, mockCompany3 };
