import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  TariffParams,
  CompaniesParams,
  YearsParams,
  TariffByYearParams,
  TariffVariantsParams,
  Company,
  Tariff,
  Variant,
  Category,
  TariffMail,
  ComparisonMail,
} from '@smart-check/feature-comparison';
import { CurrentUser, APP_CONFIG } from '@smart-check/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  public user: CurrentUser = JSON.parse(localStorage.getItem('user')!);
  private url: string;

  constructor(private http: HttpClient) {
    this.url = APP_CONFIG.csApi;
  }

  getCompanies(companiesParams: CompaniesParams): Observable<Array<Company>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<Company>>(
      `${this.url}/ajax/tariff/getCompanies/${companiesParams.idOrSlug}/${companiesParams.topFlag}`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getYears(yearsParams: YearsParams): Observable<Array<string>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<string>>(
      `${this.url}/ajax/GetYears/${yearsParams.refTariffId}/${yearsParams.companyId}/false`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getTariffByYear(
    tariffByYearParams: TariffByYearParams
  ): Observable<Array<Tariff>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<Tariff>>(
      `${this.url}/ajax/tariff/tariffByYear/${tariffByYearParams.referenceTariffId}/${tariffByYearParams.companyId}/${tariffByYearParams.year}/false?filter=%25`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getTopTariffs(
    tariffByYearParams: TariffByYearParams
  ): Observable<Array<Tariff>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<Tariff>>(
      `${this.url}/ajax/tariff/getTopTariffs/${tariffByYearParams.referenceTariffId}/${tariffByYearParams.companyId}/${tariffByYearParams.year}/true?filter=%25`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getTariffVariants(
    tariffVariantsParams: TariffVariantsParams
  ): Observable<Array<Variant>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<Variant>>(
      `${this.url}/ajax/tariff/getVariants/${tariffVariantsParams.idOrSlug}`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getSingleTariff(tariffParams: TariffParams): Observable<Tariff> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Tariff>(
      `${this.url}/singleTariff/${tariffParams.idOrSlug}/${tariffParams.withCrit}/false`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getCriterionValue(tariff: Tariff, name: any, index: number) {
    if (typeof tariff.criterions?.Criteria[name] !== 'undefined') {
      return tariff.criterions.Criteria[name][index].value;
    } else {
      return 'nicht bewertet';
    }
  }

  isCriterionSatisfied(tariff: Tariff, name: any, index: number): boolean {
    if (
      typeof tariff.criterions?.Criteria[name] !== 'undefined' &&
      tariff.criterions.Criteria[name][index].result == 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCategoryScore(tariff: Tariff, name: any): number {
    let score: number = 0;
    if (typeof tariff.criterions?.Criteria[name] !== 'undefined') {
      tariff.criterions.Criteria[name].forEach(addResult);
    }

    function addResult(item: any) {
      if (item.result == 1) {
        score += 1;
      }
    }

    return score;
  }

  getCompanyLogo(idOrSlug?: number): Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Blob>(
      `${this.url}/ajax/GetCompanyLogo/${idOrSlug}`,
      null,
      {
        headers,
        responseType: 'blob' as 'json',
        withCredentials: false,
      }
    );
  }

  getCriterions(productType: number): Observable<Array<Category>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<Category>>(
      `${this.url}/ajax/GetCriterions/${productType}`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  getCriterionsSorted(productType: number): Observable<Array<Category>> {
    let subject = new Subject<Array<Category>>();
    this.getCriterions(productType).subscribe((response) => {
      response.sort((a, b) => a.position! - b.position!);
      subject.next(response);
    });
    return subject.asObservable();
  }

  newTariffMail(tariffMail: TariffMail) {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    const formData: FormData = new FormData();
    formData.append('productType', tariffMail.productType);
    formData.append('company', tariffMail.company);
    formData.append('tariffName', tariffMail.tariffName);
    formData.append('email', tariffMail.email);
    formData.append('terms', tariffMail.terms);
    formData.append('file', tariffMail.file!);
    formData.append('notice', tariffMail.notice!);
    return this.http.post(`${this.url}/ajax/NewTariffMail`, formData, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  emailComparison(comparisonMail: ComparisonMail) {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    const formData: FormData = new FormData();
    formData.append('gender', comparisonMail.gender);
    formData.append('name', comparisonMail.name);
    formData.append('sendToConsultant', comparisonMail.sendToConsultant);
    formData.append('emailConsultant', comparisonMail.emailConsultant);
    formData.append('emailCustomer', comparisonMail.emailCustomer);
    formData.append('productType', comparisonMail.productType);
    formData.append('currentTariff', comparisonMail.currentTariff);
    formData.append('offerTariff', comparisonMail.offerTariff);
    formData.append('file', comparisonMail.file);
    return this.http.post(`${this.url}/ajax/EmailComparison`, formData, {
      headers,
      responseType: 'json',
      withCredentials: false,
    });
  }

  generatePdf(html: string, filename: string): Observable<File> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    const formData: FormData = new FormData();
    formData.append('html', html);
    formData.append('filename', filename);
    return this.http.post<File>(`${this.url}/ajax/GeneratePdf`, formData, {
      headers,
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }
}
