import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-info',
  templateUrl: './dialog-info.component.html',
})
export class DialogInfoComponent {
  public info: string;
  public title: string;
  public icon: string;
  public color: string;
  constructor(
    public dialogRef: MatDialogRef<DialogInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.info = data.info;
    this.title = data.title;
    this.icon = data.icon;
    this.color = data.color;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
