import { Tariff } from '@smart-check/feature-comparison';

const mockTariff1: Tariff = {
  id: 15887,
  name: 'BCA Premium-Schutz',
  title: 'ADCURI GmbH / BCA Premium-Schutz / Stand: 01.04.2019',
  terms: 'Stand: 01.04.2019',
  show_in_comparison: true,
  additional_information:
    'Barmenia-Leistungs-Garantie: Schadenfälle, die im Rahmen dieses Vertrages nicht unter den Deckungsschutz fallen, jedoch durch einen leistungsstärkeren Tarif eines anderen in Deutschland zum Betrieb zugelassenen Versicherers zum Zeitpunkt des Schadeneintritts eingeschlossen wären, sind automatisch entsprechend den dortigen Regelungen mitversichert.',
  is_active: true,
  created: '22.05.2019 14:49:02',
  updated: '14.02.2022 13:37:13',
  slug: 'bca-premium-schutz',
  terms_available: true,
  terms_private: true,
  active_since: '01.04.2019 00:00:00',
  importId: null,
  is_baustein_tariff: false,
  is_top_tariff: false,
  company: {
    id: 2,
  },
  product_type: {
    id: 1,
    icon: 'fa-user',
    reference_tariff_id: 18,
    criterionCount: 3,
    criterions: [],
  },
  base_tariff: null,
  variant: '',
  criterions: {
    score_reached: 50,
    score_possible: 100,
  },
};

const mockTariffVariant1: Tariff = {
  id: 15887,
  name: 'BCA Premium-Schutz',
  title: 'ADCURI GmbH / BCA Premium-Schutz / Stand: 01.04.2019',
  terms: 'Stand: 01.04.2019',
  show_in_comparison: true,
  additional_information:
    'Barmenia-Leistungs-Garantie: Schadenfälle, die im Rahmen dieses Vertrages nicht unter den Deckungsschutz fallen, jedoch durch einen leistungsstärkeren Tarif eines anderen in Deutschland zum Betrieb zugelassenen Versicherers zum Zeitpunkt des Schadeneintritts eingeschlossen wären, sind automatisch entsprechend den dortigen Regelungen mitversichert.',
  is_active: true,
  created: '22.05.2019 14:49:02',
  updated: '14.02.2022 13:37:13',
  slug: 'bca-premium-schutz',
  terms_available: true,
  terms_private: true,
  active_since: '01.04.2019 00:00:00',
  importId: null,
  is_baustein_tariff: true,
  is_top_tariff: false,
  company: {
    id: 3,
  },
  product_type: {
    id: 2,
    icon: 'fa-user-file',
    reference_tariff_id: 35,
    criterionCount: 0,
    criterions: [],
  },
  base_tariff: null,
  variant: 'Variant1',
  criterions: null,
};

const mockTariff2: Tariff = {
  id: 22769,
  name: 'Basis',
  title: 'ADCURI GmbH / Basis / Stand: 01.09.2019',
  terms: 'Stand: 01.09.2019',
  show_in_comparison: true,
  additional_information: null,
  is_active: true,
  created: '09.11.2019 07:20:11',
  updated: '14.02.2020 13:37:13',
  slug: 'basisc2ystand-01-09-2020-2',
  terms_available: true,
  terms_private: true,
  active_since: '01.09.2020 00:00:00',
  importId: null,
  is_baustein_tariff: false,
  is_top_tariff: false,
  company: {
    id: 4,
  },
  product_type: {
    id: 18,
    icon: 'fa',
    reference_tariff_id: 21,
    criterionCount: 1,
    criterions: [],
  },
  base_tariff: null,
  variant: '',
  criterions: {
    score_reached: 20,
    score_possible: 100,
  },
};

const mockTariff3: Tariff = {
  id: 22769,
  name: 'Basis',
  title: 'ADCURI GmbH / Basis / Stand: 01.09.2021',
  terms: 'Stand: 01.09.2021',
  show_in_comparison: true,
  additional_information: null,
  is_active: true,
  created: '09.11.2021 07:20:11',
  updated: '14.02.2022 13:37:13',
  slug: 'basisc2ystand-01-09-2021-2',
  terms_available: true,
  terms_private: true,
  active_since: '01.09.2021 00:00:00',
  importId: null,
  is_baustein_tariff: false,
  is_top_tariff: false,
  company: {
    id: 5,
  },
  product_type: {
    id: 99,
    icon: 'fa-2',
    reference_tariff_id: 37,
    criterionCount: 9,
    criterions: [],
  },
  base_tariff: null,
  variant: '',
  criterions: {
    score_reached: 90,
    score_possible: 100,
  },
};

const mockTariff4: Tariff = {
  id: 22769,
  name: 'Basis',
  title: 'ADCURI GmbH / Basis / Stand: 01.10.2021',
  terms: 'Stand: 01.10.2021',
  show_in_comparison: false,
  additional_information: null,
  is_active: true,
  created: '09.12.2021 07:20:11',
  updated: '14.03.2022 13:37:13',
  slug: 'basisc2ystand-01-09-2022-2',
  terms_available: true,
  terms_private: true,
  active_since: '01.09.2022 00:00:00',
  importId: null,
  is_baustein_tariff: false,
  is_top_tariff: false,
  company: {
    id: 6,
  },
  product_type: {
    id: 14,
    icon: 'fa-3',
    reference_tariff_id: 33,
    criterionCount: 0,
    criterions: [],
  },
  base_tariff: null,
  variant: '',
  criterions: {
    score_reached: 100,
    score_possible: 100,
  },
};

const mockCompanyLogo: any = { size: 3920, type: 'image/jpeg' };

const mockTariffArray: Tariff[] = [
  mockTariff1,
  mockTariff2,
  mockTariffVariant1,
];

export {
  mockTariff1,
  mockTariff2,
  mockTariff3,
  mockTariff4,
  mockTariffArray,
  mockCompanyLogo,
  mockTariffVariant1,
};
