import { ProductType } from '@smart-check/feature-comparison';

const mockProductType1: ProductType = {
  id: 1,
  name: 'Privathaftpflicht',
  slug: 'phv',
  alternative_slug: 'haftpflichtversicherung',
  import_key: 'privathaftpflicht',
  product_type_sparte_id: 1,
  position: 1,
  icon: 'fa-user-shield',
  reference_tariff_id: 33,
  criterionCount: 30,
  criterions: [],
};

const mockProductType2: ProductType = {
  id: 2,
  name: 'Hausrat',
  slug: 'hr',
  alternative_slug: null,
  import_key: 'hausrat',
  product_type_sparte_id: 1,
  position: 2,
  icon: 'fa-key',
  reference_tariff_id: 37,
  criterionCount: 20,
  criterions: [],
};

const mockProductType3: ProductType = {
  id: 3,
  name: 'Wohngebäude',
  slug: 'wg',
  alternative_slug: null,
  import_key: 'wohnebaeude',
  product_type_sparte_id: 1,
  position: 3,
  icon: 'fa-house-damage',
  reference_tariff_id: 38,
  criterionCount: 17,
  criterions: [],
};

const mockProductType4: ProductType = {
  id: 4,
  name: 'Unfall',
  slug: 'uv',
  alternative_slug: null,
  import_key: 'unfallversicherung',
  product_type_sparte_id: 1,
  position: 4,
  icon: 'fa-ambulance',
  reference_tariff_id: 42,
  criterionCount: 21,
  criterions: [],
};

const mockProductType5: ProductType = {
  id: 5,
  name: 'Kfz',
  slug: 'kfz',
  alternative_slug: null,
  import_key: 'kfz',
  product_type_sparte_id: 1,
  position: 5,
  icon: 'fa-car-crash',
  reference_tariff_id: 44,
  criterionCount: 14,
  criterions: [],
};

const mockProductType6: ProductType = {
  id: 6,
  name: 'Rechtsschutz',
  slug: 'rs',
  alternative_slug: null,
  import_key: 'rechtsschutz',
  product_type_sparte_id: 1,
  position: 6,
  icon: 'fa-gavel',
  reference_tariff_id: 43,
  criterionCount: 12,
  criterions: [],
};

const mockProductType7: ProductType = {
  id: 7,
  name: 'Tierhalterhaftpflicht (Hund)',
  slug: 'thv-hund',
  alternative_slug: null,
  import_key: 'hundehalter',
  product_type_sparte_id: 1,
  position: 7,
  icon: 'fa-paw',
  reference_tariff_id: 36,
  criterionCount: 13,
  criterions: [],
};

const mockProductType8: ProductType = {
  id: 8,
  name: 'Tierhalterhaftpflicht (Pferd)',
  slug: 'thv-pferd',
  alternative_slug: null,
  import_key: 'pferdehalter',
  product_type_sparte_id: 1,
  position: 8,
  icon: 'fa-horse',
  reference_tariff_id: 32,
  criterionCount: 15,
  criterions: [],
};

const mockProductType17: ProductType = {
  id: 17,
  name: 'Berufsunfähigkeitsversicherung',
  slug: 'berufsunfahigkeitsversicherung',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 3,
  position: 23,
  icon: 'fa-user-injured',
  reference_tariff_id: 24,
  criterionCount: 28,
  criterions: [],
};

const mockProductType18: ProductType = {
  id: 18,
  name: 'Private Krankenversicherung',
  slug: 'pkv-voll',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 2,
  position: 9,
  icon: 'fa-medkit',
  reference_tariff_id: 25,
  criterionCount: 42,
  criterions: [],
};

const mockProductType19: ProductType = {
  id: 19,
  name: 'Krankentagegeld (Angestellte)',
  slug: 'krankentagegeld-angestellte',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 2,
  position: 12,
  icon: 'fa-clinic-medical',
  reference_tariff_id: 26,
  criterionCount: 17,
  criterions: [],
};

const mockProductType21: ProductType = {
  id: 21,
  name: 'Stationäre Zusatzversicherung',
  slug: 'stationare-zusatzversicherung',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 2,
  position: 10,
  icon: 'fa-procedures',
  reference_tariff_id: 28,
  criterionCount: 8,
  criterions: [],
};

const mockProductType22: ProductType = {
  id: 22,
  name: 'Zahnzusatzversicherung',
  slug: 'zahnzusatzversicherung',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 2,
  position: 11,
  icon: 'fa-tooth',
  reference_tariff_id: 31,
  criterionCount: 15,
  criterions: [],
};

const mockProductType23: ProductType = {
  id: 23,
  name: 'Risikolebensversicherung',
  slug: 'risikolebensversicherung',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 3,
  position: 24,
  icon: 'fa-umbrella',
  reference_tariff_id: 34,
  criterionCount: 7,
  criterions: [],
};

const mockProductType24: ProductType = {
  id: 24,
  name: 'Krankentagegeld (Selbstständige)',
  slug: 'krankentagegeld-selbstandige',
  alternative_slug: null,
  import_key: null,
  product_type_sparte_id: 2,
  position: 13,
  icon: 'fa-clinic-medical',
  reference_tariff_id: 39,
  criterionCount: 17,
  criterions: [],
};

const mockProductTypeArray: ProductType[] = [
  mockProductType23,
  mockProductType17,
  mockProductType21,
  mockProductType19,
  mockProductType24,
  mockProductType18,
  mockProductType8,
  mockProductType7,
  mockProductType6,
  mockProductType5,
  mockProductType4,
  mockProductType3,
  mockProductType2,
  mockProductType1,
  mockProductType22,
];

const mockProductTypeArraySorted: ProductType[] = [
  mockProductType17,
  mockProductType2,
  mockProductType5,
  mockProductType19,
  mockProductType24,
  mockProductType18,
  mockProductType1,
  mockProductType6,
  mockProductType23,
  mockProductType21,
  mockProductType7,
  mockProductType8,
  mockProductType4,
  mockProductType3,
  mockProductType22,
];

export {
  mockProductType1,
  mockProductTypeArray,
  mockProductTypeArraySorted,
  mockProductType18,
};
