<mat-card class="flat-card">
  <form [formGroup]="reportTariffForm" novalidate>
    <mat-card-content>
      Sie haben einen Tarif entdeckt, der von uns noch nicht überprüft wurde?

      <p class="text-hint">
        <small>
          Sobald uns das entsprechende Bedingungswerk vorliegt, stellen wir
          Ihnen innerhalb weniger Werktage die Bewertung des Tarifes nach
          Verbraucherschutzkriterien zur Verfügung.
        </small>
        <br /><br />
        <small>* die mit Stern markierten Felder sind Pflichtfelder</small>
      </p>

      <mat-form-field appearance="outline">
        <mat-label>Produktart wählen</mat-label>
        <mat-select
          type="text"
          aria-label="Produktart"
          formControlName="productTypeControl"
          data-cy="report-product"
        >
          @for (product of products; track product.id) {
          <mat-option [value]="product.name">
            {{ product.name }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Gesellschaftsname</mat-label>
        <input
          type="text"
          aria-label="Gesellschaftsname"
          matInput
          formControlName="companyControl"
          data-cy="report-company"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tarifname</mat-label>
        <input
          type="text"
          aria-label="Tarifname"
          matInput
          formControlName="tariffNameControl"
          data-cy="report-tariff"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Bedingungsstand</mat-label>
        <input
          type="text"
          aria-label="Bedingungsstand"
          matInput
          formControlName="termControl"
          data-cy="report-term"
        />
      </mat-form-field>

      <mat-label>Bedingungswerk anfügen</mat-label>
      <input type="file" (change)="onFileSelected($event)" />
      <p class="mat-hint attachment-hint">
        Maximal erlaubte Dateigröße 4MB, Dateitypen
        (.pdf/.tiff/.doc/.docx/.gif/.png/.jpg) kontaktieren Sie uns bei größeren
        Anhängen über info&#64;tarifair.de
      </p>

      <mat-form-field appearance="outline" class="mat-form-field-hint">
        <mat-label>E-Mail</mat-label>
        <input
          type="email"
          aria-label="E-Mail"
          matInput
          formControlName="emailControl"
          data-cy="report-email"
        />
        <mat-hint class="email-hint"
          >Hinweis: Wir benötigen diese Angabe, um Ihnen eine Rückmeldung zum
          Bearbeitungsstand oder mögliche Rückfragen zukommen zu
          lassen.</mat-hint
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Notizfeld</mat-label>
        <textarea
          type="text"
          aria-label="Notizfeld"
          matInput
          formControlName="noticeControl"
          data-cy="report-notice"
        ></textarea>
      </mat-form-field>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button (click)="reportTariffForm.reset()">
        Zurücksetzen
      </button>
      <button
        mat-flat-button
        [disabled]="!reportTariffForm.valid || loadingSend === 1"
        (click)="sendReport(reportTariffForm)"
        data-cy="report-send"
      >
        @switch (loadingSend) { @case (0) {
        <mat-icon fontSet="fas" fontIcon="fa-paper-plane"></mat-icon>
        } @case (1) {
        <mat-icon
          ><i class="fa-solid fa-spinner fa-spin-pulse" aria-hidden="true"></i
        ></mat-icon>
        } @case (2) {
        <mat-icon fontSet="fas" fontIcon="fa-check"></mat-icon>
        } @default {
        <mat-icon fontSet="fas" fontIcon="fa-paper-plane"></mat-icon>
        } } Einsenden
      </button>
    </mat-card-actions>
  </form>
</mat-card>
