import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-legals',
  templateUrl: './dialog.legals.component.html',
})
export class DialogLegalsComponent {
  constructor(public dialogRef: MatDialogRef<DialogLegalsComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
