import { Component, Input } from '@angular/core';
import { Tariff } from '@smart-check/feature-comparison';
import {
  trigger,
  keyframes,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'tariff-check-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss'],
  animations: [
    trigger('keyframes', [
      transition(':enter', [
        animate(
          '0.8s',
          keyframes([
            style({ 'stroke-dasharray': '0, 100', offset: 0 }),
            style({ 'stroke-dasharray': '{{score}}', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class DonutComponent {
  public scorePercent: number;
  public scorePercentText: string;
  public scoreDiff: string;
  public color: string;
  @Input() tariff: Tariff;

  ngOnChanges() {
    if (this.tariff) {
      this.scorePercent =
        (this.tariff.criterions.score_reached! * 100) /
        this.tariff.criterions.score_possible!;
      this.scorePercentText =
        this.tariff.criterions.score_reached +
        ' / ' +
        this.tariff.criterions.score_possible;
      this.scoreDiff = this.scorePercent + ' ' + (100 - this.scorePercent);
      switch (true) {
        case this.scorePercent < 25:
          this.color = '#f95331';
          break;
        case this.scorePercent < 75:
          this.color = '#ff9912';
          break;
        case this.scorePercent < 100:
          this.color = '#4a9a1d';
          break;
        default:
          this.color = '#50abc2';
          break;
      }
    }
  }
}
