import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setTheme(userGroupId: number) {
    const head = this.document.getElementsByTagName('head')[0];

    const link = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${this.getScheme(userGroupId)}.css`;
    link.setAttribute('data-cy', 'themeStyle');

    head.appendChild(link);
  }

  getScheme(userGroupId: number) {
    switch (userGroupId) {
      case -1:
        return 'default';
      case 4:
        return 'pma';
      case 96:
        return 'onesty';
      case 50:
        return 'qualitypool';
      default:
        return 'default';
    }
  }

  setFavicon(userGroupId: number) {
    const head = this.document.getElementsByTagName('head')[0];

    const link = this.document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = `assets/img/favicons/${this.getScheme(userGroupId)}.png`;
    link.setAttribute('data-cy', 'favicon');

    head.appendChild(link);
  }
}
