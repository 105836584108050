const mockProductGroup1: object = { name: 'Sach' };

const mockProductGroup2: object = { name: 'Kranken' };

const mockProductGroup3: object = { name: 'Leben' };

const mockProductGroupsArray: Array<object> = [
  mockProductGroup1,
  mockProductGroup2,
  mockProductGroup3,
];

export { mockProductGroupsArray };
