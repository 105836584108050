export interface CurrentUser {
  userId?: number;
  apiKey?: string;
  session?: string;
  csrfToken: string;
  email?: string;
  smitId?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  contactDetail?: {
    name?: string;
    street?: string;
    zipcode?: string;
    city?: string;
  };
  productTypes?: Array<string>;
  productTypeIds?: Array<number>;
  productTypesSql?: string;
  productTypeIdsSql?: string;
  teamId?: number;
  userGroupId?: number;
  paymentType?: number;
  withCosts?: boolean;
  scheme?: UserScheme;
  acceptedGdpr?: number;
  wantsWerbung?: number;
  mdCustomerId?: number;
}
export interface User {
  currentUser: CurrentUser;
  result: number;
  resultText: string;
  currentTariff?: string;
  offerTariff?: string;
  productTypeId?: string;
}
export interface UserDetail {
  email?: string;
}
export interface UserScheme {
  colorScheme: string;
  vergleicheColorScheme: string;
  logoName?: string;
}
