import { APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeAnalytics } from './analytics-initializer';
import { AutomatedTrackingService } from './automated-tracking.service';
import { CustomEventDirective } from './custom-event/custom-event.directive';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAnalytics,
      multi: true,
      deps: [AutomatedTrackingService],
    },
  ],
  declarations: [CustomEventDirective],
  exports: [CustomEventDirective],
})
export class SharedUtilAnalyticsModule {}
