<tariff-check-header></tariff-check-header>
<mat-toolbar class="second-toolbar">
  <button
    mat-icon-button
    data-cy="sidenav-button-return"
    routerLink="/productSelect"
    data-cy="back"
  >
    <mat-icon fontSet="fas" fontIcon="fa-arrow-left"></mat-icon>
  </button>
  <div class="toolbar-title theme-color-primary center" data-cy="title">
    Bestehenden Tarif auswählen
  </div>

  <button mat-icon-button (click)="toggleSidenav(0)" data-cy="select-product">
    <mat-icon fontSet="fas" fontIcon="fa-list-radio"></mat-icon>
  </button>
  <button mat-icon-button (click)="toggleSidenav(1)" data-cy="report">
    <mat-icon fontSet="fas" fontIcon="fa-bullhorn"></mat-icon>
  </button>
</mat-toolbar>
<mat-drawer-container hasBackdrop="true">
  <mat-drawer
    #sidenav
    opened="false"
    position="end"
    mode="over"
    fixedTopGap="56"
  >
    <div class="flex-row justify-between">
      <div class="drawer-title" data-cy="drawer-title">
        {{ sideNavigation.title }}
      </div>
      <button mat-icon-button (click)="sidenav.close()" data-cy="close">
        <mat-icon id="sidenavBtn" fontSet="far" fontIcon="fa-times"></mat-icon>
      </button>
    </div>
    @if (sideNavigation.id == 0) {
    <product-navigation></product-navigation>
    } @if (sideNavigation.id == 1) {
    <tariff-report (closeSidenav)="sidenav.close()"></tariff-report>
    }
  </mat-drawer>
  <main>
    <mat-card class="card-container">
      <form-tariff
        [data]="data"
        (callToggleSidenav)="toggleSidenavForm($event)"
      ></form-tariff>
    </mat-card>
  </main>
</mat-drawer-container>
