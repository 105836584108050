import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ProductType,
  ProductGroups,
  ProductService,
} from '@smart-check/feature-comparison';
@Component({
  selector: 'product-navigation',
  templateUrl: './product-navigation.component.html',
  styleUrls: ['./product-navigation.component.scss'],
})
export class ProductNavigationComponent implements OnInit {
  productGroups: Array<ProductGroups>;
  products: Array<ProductType>;
  filteredOptions: Observable<any[]>;
  constructor(private productService: ProductService, private router: Router) {}

  ngOnInit(): void {
    this.productGroups = this.productService.getProductGroups();
    this.productService.getProductTypeSorted(false).subscribe({
      next: (response: Array<ProductType>) => {
        this.products = response;
      },
    });
  }

  findByCategory(category: ProductType[], index: number): ProductType[] {
    return this.productService.findByCategory(category, index);
  }

  onSelectionChange(event: any) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigate(['tariffCheck', event.options[0].value.id, 'null'])
      );
  }
}
