import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DialogCriterionComponent } from './../dialog/dialog-criterion/dialog-criterion.component';
import { MatDialog } from '@angular/material/dialog';
import {
  Category,
  ProductService,
  ProductType,
} from '@smart-check/feature-comparison';

@Component({
  selector: 'criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss'],
})
export class CriteriaComponent implements OnInit {
  public category: Array<Category>;
  public products: Array<ProductType>;
  constructor(
    private location: Location,
    private productService: ProductService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.productService
      .getProductTypeSorted(true)
      .subscribe((response) => (this.products = response));
  }

  showCriterionInfo(name: string | undefined, aboutLong: string | undefined) {
    this.dialog.open(DialogCriterionComponent, {
      data: { name, aboutLong },
    });
  }

  back(): void {
    this.location.back();
  }
}
