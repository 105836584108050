import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@smart-check/feature-comparison';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import {
  NotificationService,
  ThemeService,
  User,
} from '@smart-check/shared/ui';

@Component({
  selector: 'password',
  templateUrl: './password.component.html',
})
export class PasswordComponent implements OnInit {
  public csrf1: string;
  public csrf2: string;
  public badRequest: boolean = true;
  public userGroupId = -1;
  public newPasswordHidden: boolean = true;
  public newPasswordRepeatHidden: boolean = true;
  public resetPasswordForm: FormGroup = this.formBuilder.group({});

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private injector: Injector,
    private themeService: ThemeService
  ) {
    this.resetPasswordForm = new FormGroup({
      newPasswordControl: new FormControl('', [Validators.required]),
      newPasswordRepeatControl: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.csrf1 = this.route.snapshot.paramMap.get('csrfToken')!;
    this.themeService.setTheme(this.userGroupId);
    this.themeService.setFavicon(this.userGroupId);
    this.userService.checkToken(this.csrf1).subscribe({
      next: (response: User) => {
        if (response.result === 0) {
          this.badRequest = false;
          this.csrf2 = response.currentUser.csrfToken;
        }
      },
    });
  }

  focusOutNewPassword() {
    const password = this.resetPasswordForm.get('newPasswordControl')?.value;
    const confirmPassword = this.resetPasswordForm.get(
      'newPasswordRepeatControl'
    )?.value;

    if (password !== confirmPassword) {
      this.resetPasswordForm
        .get('newPasswordRepeatControl')
        ?.setErrors({ incorrect: true });
    }
  }

  close() {
    this.router.navigate(['/home']);
  }

  setNewPassword(resetPasswordForm: FormGroup, csrf2: string) {
    const newPW: string = resetPasswordForm.get(
      'newPasswordRepeatControl'
    )?.value;
    this.userService.resetPassword(newPW, this.csrf1, csrf2).subscribe({
      next: (response) => {
        if (response === 0) {
          const notifier = this.injector.get(NotificationService);
          notifier.showSuccess(
            'Sie können sich mit Ihrem neuen Passwort einloggen.'
          );
          this.router.navigate(['/home']);
        }
      },
    });
  }
}
