import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-survey',
  templateUrl: './dialog-survey.component.html',
})
export class DialogSurveyComponent {
  constructor(public dialogRef: MatDialogRef<DialogSurveyComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  umbuzooPopup() {
    let theWindow = window.open(
      'https://umfrage.smartinsurtech.de/d/61f7dbe8c15cac06b21ba28a/de/',
      'UmbuzooSurvey',
      'width=800,height=600,status=yes,scrollbars=yes,resizable=yes,target=_blank,rel=noopener'
    );
    if (theWindow) {
      theWindow.focus();
      this.dialogRef.close();
    }
  }
}
