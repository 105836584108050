import { Variant } from '@smart-check/feature-comparison';

const mockVariant1: Variant = {
  id: 20024,
  tariffName: 'A 112 A',
  tags: ['K 30A', 'Z 100/80 A'],
};

const mockVariant2: Variant = {
  id: 20054,
  tariffName: 'A 112 A',
  tags: ['K 30A', 'Z 80/60 A'],
};

const mockVariant3: Variant = {
  id: 20064,
  tariffName: 'A 112 A',
  tags: ['K 20A', 'Z 100/80 A'],
};

const mockVariant4: Variant = {
  id: 20074,
  tariffName: 'A 112 A',
  tags: ['K 20A', 'Z 80/60 A'],
};

const mockVariant5: Variant = {
  id: 20084,
  tariffName: 'A 112 A',
  tags: ['K 20A', 'K/S A', 'Z 100/80 A'],
};

const mockVariant6: Variant = {
  id: 20094,
  tariffName: 'A 112 A',
  tags: ['K 20A', 'K/S A', 'Z 80/60 A'],
};

const mockVariant7: Variant = {
  id: 20094,
  tariffName: 'A 112 A',
  tags: [],
};

const mockVariantArray: Variant[] = [
  mockVariant1,
  mockVariant2,
  mockVariant3,
  mockVariant4,
  mockVariant5,
  mockVariant6,
];

export { mockVariant1, mockVariantArray, mockVariant7 };
