import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TariffSelection } from '@smart-check/feature-comparison';

@Component({
  selector: 'dialog-tariff',
  templateUrl: './dialog-tariff.component.html',
})
export class DialogTariffComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogTariffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TariffSelection
  ) {}
}
