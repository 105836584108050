<div mat-dialog-title data-cy="title">
  <mat-icon fontSet="far" fontIcon="fa-landmark"></mat-icon
  >Rechtliche Informationen
</div>
<mat-dialog-content>
  <ul class="no-dot">
    <li>
      <a
        href="#"
        onclick="window.open('assets/doc/Impressum.pdf', '_blank', 'fullscreen=yes'); return false;"
        >Impressum&nbsp;<mat-icon
          fontSet="far"
          fontIcon="fa-external-link"
        ></mat-icon
      ></a>
    </li>
    <li>
      <a
        href="#"
        onclick="window.open('assets/doc/smit-allgemeine_geschaeftsbedingungen.pdf', '_blank', 'fullscreen=yes'); return false;"
        >Allgemeine Geschäftsbedingungen&nbsp;<mat-icon
          color="primary"
          fontSet="far"
          fontIcon="fa-external-link"
        ></mat-icon
      ></a>
    </li>
    <li>
      <a
        href="#"
        onclick="window.open('assets/doc/privacy.pdf', '_blank', 'fullscreen=yes'); return false;"
        >Datenschutzvereinbarung&nbsp;<mat-icon
          color="primary"
          fontSet="far"
          fontIcon="fa-external-link"
        ></mat-icon
      ></a>
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()" data-cy="close">
    Schließen
  </button>
</mat-dialog-actions>
