import { ComparisonMail, TariffMail } from '@smart-check/feature-comparison';

const mockTariffMail: TariffMail = {
  productType: '1',
  company: 'Arag',
  tariffName: 'tariff1',
  terms: '2022',
  email: 'bud@snirgel.de',
};

const mockComparisonMail: ComparisonMail = {
  gender: 'f',
  name: 'Bud Snirgel',
  sendToConsultant: 'true',
  emailConsultant: '',
  emailCustomer: 'bud@snirgel.de',
  productType: 'Privathaftpflicht',
  currentTariff: 'ADCURI GmbH / BCA Premium-Schutz / Stand: 01.04.2019',
  offerTariff: 'ADCURI GmbH / BCA Premium-Schutz / Stand: 01.04.2019',
  file: new File([''], 'filename', { type: 'text/html' }),
};

export { mockTariffMail, mockComparisonMail };
