import {
  TariffParams,
  YearsParams,
  TariffByYearParams,
  TariffVariantsParams,
  VariantsParams,
  CompaniesParams,
  HasSeenInfoParams,
  ProductTypeParams,
  ConsumptionParams,
  LogoParams,
  CriterionsParams,
} from '@smart-check/feature-comparison';

const mockTariffParams: TariffParams = {
  idOrSlug: '1456',
  withCrit: false,
  onlyActive: true,
};

const mockYearsParams: YearsParams = {
  refTariffId: 1,
  companyId: 1456,
  onlyActive: false,
};

const mockTariffByYearParams: TariffByYearParams = {
  referenceTariffId: 1,
  companyId: 1456,
  year: '-1',
  onlyActive: true,
};

const mockTariffVariantsParams: TariffVariantsParams = {
  idOrSlug: '1456',
};

const mockVariantsParams: VariantsParams = {
  idOrSlug: '1456',
};

const mockCompaniesParams: CompaniesParams = {
  idOrSlug: '1456',
  topFlag: false,
};

const mockHasSeenInfoParams: HasSeenInfoParams = {
  slug: '1456',
  toggle: false,
};

const mockProductTypeParams: ProductTypeParams = {
  withCriterion: false,
};

const mockConsumptionParams: ConsumptionParams = {
  von: '01.01.2021',
  bis: '31.12.2021',
};

const mockLogoParams: LogoParams = {
  idOrSlug: '14',
};

const mockCriterionsParams: CriterionsParams = {
  productType: 1,
};

export {
  mockTariffParams,
  mockYearsParams,
  mockTariffByYearParams,
  mockTariffVariantsParams,
  mockVariantsParams,
  mockCompaniesParams,
  mockHasSeenInfoParams,
  mockProductTypeParams,
  mockConsumptionParams,
  mockLogoParams,
  mockCriterionsParams,
};
