@if (products) {
<mat-tab-group [selectedIndex]="0" animationDuration="0ms">
  @for (productGroup of productGroups; track productGroup.name; let i = $index)
  {
  <mat-tab label="{{ productGroup.name }}">
    <mat-selection-list
      id="productList"
      [multiple]="false"
      (selectionChange)="onSelectionChange($event)"
      hideSingleSelectionIndicator="true"
    >
      @for (product of findByCategory(products, i); track product.id; let last =
      $last) {
      <mat-list-option [value]="product"
        ><mat-icon
          mat-list-icon
          fontSet="fas"
          [fontIcon]="product.icon"
          class="theme-color-primary"
        ></mat-icon
        >{{ product.name }}@if (!last) {
        <mat-divider></mat-divider>
        }</mat-list-option
      >
      } @empty {
      <p class="empty-list">
        Das Paket <strong>{{ productGroup.name }}</strong> wurde nicht
        freigeschaltet.
      </p>
      }
    </mat-selection-list>
  </mat-tab>
  }
</mat-tab-group>
}
