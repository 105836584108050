import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[analyticsEvent]',
})
export class CustomEventMockDirective {
  @Input() analyticsEvent: string;

  @Input() analyticsData: any;
}
