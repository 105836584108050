<div mat-dialog-title data-cy="title">
  <mat-icon
    fontSet="far"
    fontIcon="fa-question-circle"
  ></mat-icon
  >Support
</div>
<mat-dialog-content>
  <div class="flex-col gap-4">
    <div>
      <div>
        <mat-card-title
          ><mat-icon
            fontSet="far"
            fontIcon="fa-ticket-simple"
          ></mat-icon
          >Ticket</mat-card-title
        >
      </div>
      <div>
        Bitte wenden Sie sich bei fachlichen und technischen Fragen an unsere
        Support-Emailadresse.<br />
        <a href="mailto:info&#64;tarifair.de">info&#64;tarifair.de</a>
        <br />
        <br />Wir werden Ihr Anliegen umgehend bearbeiten und uns mit Ihnen in
        Verbindung setzen.
      </div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <div>
        <mat-card-title
          ><mat-icon
            fontSet="far"
            fontIcon="fa-user-headset"
          ></mat-icon
          >Hotline</mat-card-title
        >
      </div>
      <div>
        Über die Hotline +49 30 233237-0 werden alle fachlichen und technischen
        Anliegen aufgenommen und ggf. direkt mit Ihnen geklärt.<br />
        <br />
        Wir sind von Montag bis Donnerstag ab 08:00 bis 17:00 Uhr und Freitags
        bis 14:00 Uhr erreichbar.
      </div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <div>
        <mat-card-title
          ><mat-icon color="primary" fontSet="far" fontIcon="fa-book"></mat-icon
          >Handbuch</mat-card-title
        >
      </div>
      <div>
        <a
          href="../../../assets/doc/Handbuch_Smart CHECK_April 2023.pdf"
          target="_blank"
          >SmartCheck Handbuch April 2023&nbsp;<mat-icon
            fontSet="far"
            fontIcon="fa-external-link"
          ></mat-icon
        ></a>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()" data-cy="close">
    Schließen
  </button>
</mat-dialog-actions>
