<form [formGroup]="selectTariffForm" novalidate>
  @if(data.currentProductType) {
  <mat-card-header>
    <mat-card-title data-cy="product-title">{{
      data.currentProductType.name
    }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-icon
      class="card-icon"
      fontSet="far"
      [fontIcon]="data.currentProductType.icon"
    ></mat-icon>
    @if(data.isOffer) {
    <div class="form-group form-toggle">
      <mat-slide-toggle
        id="topTariffToggle"
        (change)="getTopTariff($event)"
        data-cy="tariff-only-top"
        >nur aktuelle Top-Tarife anzeigen</mat-slide-toggle
      >
    </div>
    } @if(!hasTopTariff) {
    <div>Keine Top-Tarife vorhanden</div>
    } @if (companies && hasTopTariff) {
    <mat-form-field appearance="outline">
      <mat-label>Gesellschaft</mat-label>
      @if (selectTariffForm.get('companyControl')?.disabled) {
      <mat-icon class="spin"
        ><i class="fa-solid fa-spinner fa-spin-pulse" aria-hidden="true"></i
      ></mat-icon>
      }
      <input
        id="companyInput"
        type="text"
        placeholder="Anbieter wählen"
        aria-label="Gesellschaft"
        matInput
        formControlName="companyControl"
        [matAutocomplete]="autoCompany"
        data-cy="tariff-company"
        (keydown.enter)="onEnter($event)"
      />
      <mat-autocomplete #autoCompany="matAutocomplete">
        @for (company of filteredCompaniesOptions | async; track company.id) {
        <mat-option
          value="{{ company.short_name }}"
          (onSelectionChange)="selectCompany(company, $event)"
        >
          {{ company.short_name! }}
        </mat-option>
        }
      </mat-autocomplete>
      @if (selectTariffForm.get('companyControl')?.valid) {
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="selectTariffForm.get('companyControl')?.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
      }
    </mat-form-field>
    } @if (selectTariffForm.get('companyControl')?.valid) {
    <mat-form-field appearance="outline">
      <mat-label>Jahr</mat-label>
      <input
        id="yearInput"
        type="text"
        placeholder="Jahr wählen"
        aria-label="Jahr"
        matInput
        formControlName="yearControl"
        [matAutocomplete]="autoYear"
        data-cy="tariff-year"
        (keydown.enter)="onEnter($event)"
      />
      <mat-autocomplete #autoYear="matAutocomplete">
        @for (year of years; track year) {
        <mat-option
          [value]="year"
          (onSelectionChange)="selectYear(year, $event)"
        >
          {{ year }}
        </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    } @if (selectTariffForm.get('companyControl')?.valid) {
    <mat-form-field appearance="outline">
      <mat-label>Tarifname</mat-label>
      @if (selectTariffForm.get('tariffControl')?.disabled) {
      <mat-icon class="spin"
        ><i class="fa-solid fa-spinner fa-spin-pulse" aria-hidden="true"></i
      ></mat-icon>
      }

      <input
        id="tariffInput"
        type="text"
        placeholder="Tarif wählen"
        aria-label="Tarifname"
        matInput
        formControlName="tariffControl"
        [matAutocomplete]="autoTariff"
        data-cy="tariff-name"
        (keydown.enter)="onEnter($event)"
      />
      <mat-autocomplete #autoTariff="matAutocomplete">
        @for (tariff of filteredTariffsOptions | async; track tariff.id) {
        <mat-option
          value="{{ tariff.name }}, {{ tariff.terms }}"
          (onSelectionChange)="selectTariff(tariff, $event)"
        >
          {{ tariff.name }}, {{ tariff.terms }}
        </mat-option>
        }
      </mat-autocomplete>
      @if (selectTariffForm.get('tariffControl')?.valid) {
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="selectTariffForm.get('tariffControl')?.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
      }
    </mat-form-field>
    } @if ( tariffVariants && selectTariffForm.get('tariffControl')?.valid &&
    selectTariffForm.get('companyControl')?.valid) {
    <mat-form-field appearance="outline">
      <mat-label>Baustein</mat-label>
      @if (selectTariffForm.get('tariffVariantControl')?.disabled) {
      <mat-icon class="spin"
        ><i class="fa-solid fa-spinner fa-spin-pulse" aria-hidden="true"></i
      ></mat-icon>
      }

      <input
        id="variantInput"
        type="text"
        placeholder="Baustein wählen"
        aria-label="Baustein"
        matInput
        formControlName="tariffVariantControl"
        [matAutocomplete]="autoTariffVariant"
        data-cy="tariff-variant"
        (keydown.enter)="onEnter($event)"
      />
      <mat-autocomplete #autoTariffVariant="matAutocomplete">
        @for (tariffVariant of filteredVariantsOptions | async; track
        tariffVariant.id) {
        <mat-option
          [value]="tariffVariant.tags"
          (onSelectionChange)="selectTariffVariant(tariffVariant, $event)"
        >
          {{ tariffVariant.tags }}
        </mat-option>
        }
      </mat-autocomplete>
      @if (selectTariffForm.get('tariffVariantControl')?.valid) {
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="selectTariffForm.get('tariffVariantControl')?.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
      }
    </mat-form-field>
    }
  </mat-card-content>
  <mat-card-actions>
    @if (isDialog) {
    <button mat-button (click)="onNoClick()">Abbrechen</button>
    } @else {
    <button mat-button (click)="toggleSidenavForm(1)">
      <mat-icon fontSet="fas" fontIcon="fa-bullhorn"></mat-icon>Tarif melden
    </button>
    }

    <button
      mat-flat-button
      type="submit"
      id="analyseBtn"
      [disabled]="!selectTariffForm.valid"
      (click)="tariffAnalyse(selectedTariff.id)"
      #btnSubmit="matButton"
      data-cy="tariff-analyse"
    >
      <mat-icon fontSet="fas" fontIcon="fa-magnifying-glass"></mat-icon
      >Analysieren
    </button>
  </mat-card-actions>
  }
</form>
