import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  Tariff,
  TariffParams,
  Sidenav,
  TariffService,
  ProductService,
  SidenavService,
  Category,
  ProductType,
} from '@smart-check/feature-comparison';
import { DialogCriterionComponent } from '../dialog/dialog-criterion/dialog-criterion.component';
import { DialogInfoComponent } from '../dialog/dialog-info/dialog-info.component';
import { DialogTariffComponent } from '../dialog/dialog-tariff/dialog-tariff.component';
import { MatSidenav } from '@angular/material/sidenav';
import { DialogProductComponent } from '../dialog/dialog-product/dialog-product.component';

@Component({
  selector: 'tariff-check',
  templateUrl: './tariff-check.component.html',
  styleUrls: ['./tariff-check.component.scss'],
})
export class TariffCheckComponent implements OnInit {
  public sideNavigation: Sidenav = {
    id: 0,
    title: '',
  };
  public currentProductType: ProductType = {
    id: 0,
    name: '',
    slug: '',
    alternative_slug: '',
    import_key: '',
    product_type_sparte_id: 0,
    position: 0,
    icon: '',
    reference_tariff_id: 0,
    criterionCount: 0,
    criterions: [],
  };
  public categories: Array<Category>;
  public product: string;
  public currentTariff: Tariff | undefined;
  public offerTariff: Tariff | undefined;
  public currentCompanyLogoToShow: string | ArrayBuffer | null;
  public offerCompanyLogoToShow: string | ArrayBuffer | null;
  public isCurrentLogoLoaded: boolean;
  public isOfferLogoLoaded: boolean;
  public status: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tariffService: TariffService,
    private sidenavService: SidenavService,
    private productService: ProductService,
    private dialog: MatDialog
  ) {}

  @ViewChild('sidenav') sidenav: MatSidenav;

  ngOnInit(): void {
    this.currentProductType.id = parseInt(
      this.route.snapshot.paramMap.get('product')!
    );

    this.productService
      .getCurrentProductType(this.currentProductType.id)
      .subscribe({
        next: (response: ProductType) => {
          this.currentProductType = response;
        },
      });

    this.tariffService
      .getCriterionsSorted(this.currentProductType.id)
      .subscribe({
        next: (response: Array<Category>) => {
          this.categories = response;
        },
      });

    this.route.params.subscribe((paramMap) => {
      if (paramMap['currentTariff'] !== 'null') {
        const currentTariffParams: TariffParams = {
          idOrSlug: paramMap['currentTariff'],
          withCrit: true,
          onlyActive: false,
        };
        this.showCurrentTariff(currentTariffParams);
      }

      if (paramMap['offerTariff']) {
        const offerTariffParams: TariffParams = {
          idOrSlug: paramMap['offerTariff'],
          withCrit: true,
          onlyActive: true,
        };
        this.showOfferTariff(offerTariffParams);
      }
    });
  }

  getProductFullName(id: number): string {
    return this.productService.getProductName(id);
  }

  getProductShortName(id: number): string {
    return this.productService.getProductShortName(id);
  }

  showCurrentTariff(currentTariffParams: TariffParams) {
    this.tariffService.getSingleTariff(currentTariffParams).subscribe({
      next: (response: Tariff) => {
        this.currentTariff = response;
        this.tariffService
          .getCompanyLogo(this.currentTariff.company?.id)
          .subscribe({
            next: (response: Blob) => {
              this.createImageFromBlobCurrentCompany(response);
              this.isCurrentLogoLoaded = true;
            },
            error: (error) => {
              this.isCurrentLogoLoaded = false;
            },
          });
      },
    });
  }

  showOfferTariff(offerTariffParams: TariffParams) {
    this.tariffService.getSingleTariff(offerTariffParams).subscribe({
      next: (response: Tariff) => {
        this.offerTariff = response;
        this.tariffService
          .getCompanyLogo(this.offerTariff.company?.id)
          .subscribe({
            next: (response: Blob) => {
              this.createImageFromBlobOfferCompany(response);
              this.isOfferLogoLoaded = true;
            },
            error: (error) => {
              this.isOfferLogoLoaded = false;
            },
          });
      },
    });
  }

  toggleSidenav(id: number) {
    this.sideNavigation = this.sidenavService.getSidenav(id);
    if (this.sidenav) {
      this.sidenav.open();
    }
  }

  showCriterionInfo(name: string | undefined, aboutLong: string | undefined) {
    this.dialog.open(DialogCriterionComponent, {
      data: { name, aboutLong },
    });
  }

  showInfoDialog(info: string, title: string, icon: string, color: string) {
    this.dialog.open(DialogInfoComponent, {
      data: { info, title, icon, color },
    });
  }

  createImageFromBlobCurrentCompany(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.currentCompanyLogoToShow = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  createImageFromBlobOfferCompany(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.offerCompanyLogoToShow = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getCriterionValue(tariff: Tariff, name: any, index: number): string {
    return this.tariffService.getCriterionValue(tariff, name, index);
  }

  isCriterionSatisfied(tariff: Tariff, name: any, index: number): boolean {
    return this.tariffService.isCriterionSatisfied(tariff, name, index);
  }

  getCategoryScore(tariff: Tariff, name: any): number {
    return this.tariffService.getCategoryScore(tariff, name);
  }

  showTariffDialog(
    currentProductType: ProductType,
    currentTariff: Tariff | undefined,
    offerTariff: Tariff | undefined,
    isOffer: boolean
  ) {
    this.dialog.open(DialogTariffComponent, {
      autoFocus: false,
      disableClose: false,
      data: { currentProductType, currentTariff, offerTariff, isOffer },
    });
  }

  openPdfPreview() {
    if (this.offerTariff && this.currentTariff) {
      this.router.navigate([
        '/pdf',
        this.currentProductType.id,
        this.currentTariff?.id,
        this.offerTariff?.id,
      ]);
    }
    if (this.currentTariff && !this.offerTariff) {
      this.router.navigate([
        '/pdf',
        this.currentProductType.id,
        this.currentTariff?.id,
      ]);
    }
    if (!this.currentTariff && this.offerTariff) {
      this.router.navigate([
        '/pdf',
        this.currentProductType.id,
        'null',
        this.offerTariff?.id,
      ]);
    }
  }

  loader() {
    this.status = !this.status;
  }

  openDialog(): void {
    this.dialog.open(DialogProductComponent);
  }
}
