import { Injectable } from '@angular/core';
import { Sidenav } from '@smart-check/feature-comparison';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  getSidenav(id: number): Sidenav {
    switch (id) {
      case 0:
        return {
          id: id,
          title: 'Produktauswahl',
        };
      case 1:
        return {
          id: id,
          title: 'Fehlenden Tarif melden',
        };
      case 2:
        return {
          id: id,
          title: 'Tarif drucken',
        };
      case 3:
        return {
          id: id,
          title: 'Tarif per E-Mail versenden',
        };
      default:
        return {
          id: id,
          title: '',
        };
    }
  }
}
