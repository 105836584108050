export class Login {
  constructor(public userName: string, public password: string) {
    this.userName = userName;
    this.password = password;
  }
}

export class ChangePassword {
  csrf?: string;
  oldPW?: string;
  newPW?: string;
}
export class GetUserDetails {
  csrf?: string;
}

export class SetUserDetails {
  csrf?: string;
}

export class Mail {
  constructor(
    public name: string,
    public email: string,
    public company?: string,
    public phone?: string,
    public address?: string
  ) {
    this.email = email;
    this.company = company;
    this.name = name;
    this.phone = phone;
    this.address = address;
  }
}
