<div mat-dialog-title>
  <mat-icon fontSet="far" fontIcon="fa-info-circle"></mat-icon>
  <span>Info zum Leistungskriterium</span>
</div>
<mat-dialog-content>
  <p><strong>Kriterium:</strong> {{ name }}</p>
  <mat-divider></mat-divider>
  <p> {{ aboutLong }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()">Schließen</button>
</mat-dialog-actions>
