import { Consumption, ConsumptionData } from '@smart-check/feature-comparison';

const mockConsumption: Consumption = {
  '1': 248,
  '2': 1818,
  '4': 82,
  '5': 256,
  '6': 196,
  '7': 31,
  '8': 3,
  '21': 2,
  '22': 2,
};

const mockConsumptionData: Array<ConsumptionData> = [
  { month: 1, checks: 10 },
  { month: 2, checks: 20 },
  { month: 3, checks: 30 },
  { month: 4, checks: 40 },
  { month: 5, checks: 50 },
  { month: 6, checks: 60 },
  { month: 7, checks: 70 },
  { month: 8, checks: 80 },
  { month: 9, checks: 90 },
  { month: 10, checks: 100 },
  { month: 11, checks: 110 },
  { month: 12, checks: 120 },
];

export { mockConsumption, mockConsumptionData };
