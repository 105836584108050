import { Component, Injector } from '@angular/core';
import { Mail, MailService, NotificationService } from '@smart-check/shared/ui';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dialog-test',
  templateUrl: './dialog-test.component.html',
  styleUrls: ['./../../home/home.component.scss'],
})
export class DialogTestComponent {
  public testForm: FormGroup;
  public mail: Mail = {
    email: '',
    company: '',
    name: '',
    phone: '',
    address: '',
  };
  constructor(
    public dialogRef: MatDialogRef<DialogTestComponent>,
    public mailService: MailService,
    private injector: Injector
  ) {
    this.testForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$'),
      ]),
      company: new FormControl(),
      address: new FormControl(),
      phone: new FormControl(),
      agreeConditions: new FormControl('', [
        (control) => {
          return !control.value ? { required: true } : null;
        },
      ]),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changedConditions(event: any) {
    if (event.checked) {
      const element = document.getElementById('conditions');
      element?.classList.remove('error');
    }
  }

  onSubmit() {
    const notifier = this.injector.get(NotificationService);
    if (this.testForm.valid) {
      this.mail.email = this.testForm.get('email')?.value;
      this.mail.company = this.testForm.get('company')?.value;
      this.mail.name = this.testForm.get('name')?.value;
      this.mail.phone = this.testForm.get('phone')?.value;
      this.mail.address = this.testForm.get('address')?.value;
      this.mailService.sendMail(this.mail).subscribe({
        next: (response: any) => {
          if (response.result === -2) {
            notifier.showError('Diese E-Mail-Adresse wird bereits verwendet');
          } else if (response.result !== -1) {
            notifier.showSuccess(
              'Vielen Dank für Ihr Interesse an Smart CHECK. Sie erhalten die Zugangsdaten für Ihren Testaccount in Kürze per Mail'
            );
          }
        },
      });
      this.onNoClick();
    } else {
      if (this.testForm.get('agreeConditions')?.invalid) {
        const element = document.getElementById('conditions');
        element?.classList.add('error');
      }
    }
  }
}
