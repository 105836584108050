<div mat-dialog-title>
  <span data-cy="dialog-title">Testzugang anfordern</span>
</div>
<form id="testForm" [formGroup]="testForm" (ngSubmit)="onSubmit()" novalidate>
  <mat-dialog-content>
    <div class="info-box">
      <div class="flex-row justify-between gap-8">
        <div><i class="fa-2x far fa-info-circle" aria-hidden="true"></i></div>
        <div>
          Nutzen Sie SMART Check ohne Einschränkungen für 14 Tage Hinweis: Der
          Testzugang ist unverbindlich, kostenfrei und wird nach Ablauf der
          Testphase automatisch deaktiviert. Es bedarf keiner Kündigung.
        </div>
      </div>
    </div>

    <div class="flex-row flex-col-sm gap-16 gap-0-sm">
      <mat-form-field class="flex-grow" appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Name"
          formControlName="name"
          autocomplete="name"
          data-cy="name"
        />
        @if( testForm.get('name')?.invalid && (testForm.get('name')?.dirty ||
        testForm.get('name')?.touched) &&
        testForm.get('name')?.errors?.['required']){
        <mat-error> Name ist erforderlich </mat-error>
        }
      </mat-form-field>
      <mat-form-field class="flex-grow" appearance="outline">
        <mat-label>E-Mail</mat-label>
        <input
          matInput
          type="email"
          placeholder="E-Mail"
          formControlName="email"
          data-cy="email"
        />
        @if( !testForm.get('email')?.errors?.['required'] &&
        testForm.get('email')?.errors?.['pattern']) {
        <mat-error> Bitte geben Sie eine gültige E-Mail Adresse ein </mat-error>
        } @if( testForm.get('email')?.invalid && (testForm.get('email')?.dirty
        || testForm.get('email')?.touched) &&
        testForm.get('email')?.errors?.['required']) {
        <mat-error> E-Mail ist erforderlich </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="flex-row flex-col-sm justify-between gap-16 gap-0-sm">
      <mat-form-field class="flex-grow" appearance="outline">
        <mat-label>Firma</mat-label>
        <input
          matInput
          type="text"
          placeholder="Firma"
          formControlName="company"
          data-cy="company"
        />
      </mat-form-field>
      <mat-form-field class="flex-grow" appearance="outline">
        <mat-label>Telefon</mat-label>
        <input
          matInput
          type="text"
          placeholder="Telefon"
          formControlName="phone"
          data-cy="phone"
        />
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Anschrift</mat-label>
      <input
        matInput
        type="text"
        placeholder="Anschrift"
        formControlName="address"
        data-cy="address"
      />
    </mat-form-field>
    <div class="flex-row justify-between gap-8">
      <div>
        <mat-checkbox
          formControlName="agreeConditions"
          (change)="changedConditions($event)"
          data-cy="agreeConditions"
        ></mat-checkbox>
      </div>

      <div id="conditions">
        Mit Setzen des Häkchens erklären Sie sich einverstanden, dass die von
        Ihnen angegeben Daten elektronisch erhoben und gespeichert werden.
        Weitere Informationen entnehmen Sie der
        <a
          href="#"
          onclick="window.open('assets/doc/privacy.pdf', '_blank', 'fullscreen=yes'); return false;"
          >Datenschutzerklärung</a
        >.
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      data-cy="dialog-close"
      (click)="onNoClick()"
    >
      Schließen
    </button>
    <button
      mat-flat-button
      data-cy="test-submit"
      id="submit"
      mat-button
    >
      Senden
    </button>
  </mat-dialog-actions>
</form>
