<div mat-dialog-title data-cy="title">
  <mat-icon
    class="theme-color-primary"
    fontSet="far"
    fontIcon="fa-clipboard-question"
  ></mat-icon
  ><span class="theme-color-primary">Umfrage zu Smart CHECK</span>
</div>
<mat-dialog-content>
  <h3>Wir freuen uns über Ihre Unterstützung</h3>
  <p>
    Wir möchten Smart CHECK (tarifair) stetig verbessern. Daher freuen wir uns,
    wenn Sie sich die Zeit nehmen, um diese Umfrage zu beantworten. Die Dauer
    beträgt max. 1 Minute.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()" data-cy="close">
    Nein, Danke
  </button>
  <button mat-flat-button (click)="umbuzooPopup()">Starten</button>
</mat-dialog-actions>
