<div mat-dialog-title>
  <mat-icon
    color="{{ color }}"
    fontSet="far"
    fontIcon="fa-{{ icon }}"
  ></mat-icon>
  <span>{{ title }}</span>
</div>
<mat-dialog-content data-cy="info">
  {{ info }}
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()">Schließen</button>
</mat-dialog-actions>
