<tariff-check-header></tariff-check-header>
<mat-toolbar class="second-toolbar">
  <button
    mat-icon-button
    data-cy="sidenav-button-return"
    data-cy="back"
    (click)="back()"
  >
    <mat-icon fontSet="fas" fontIcon="fa-arrow-left" color="primary"></mat-icon>
  </button>
  <div class="toolbar-title theme-color-primary center" data-cy="title">
    Verbraucherschutz-Kriterien im Überblick
  </div>
</mat-toolbar>
<main>
  <mat-card class="card-container">
    <mat-card-content>
      Der Vergleich von Versicherungsbedingungen mit Smart Check basiert auf den
      Leistungskriterien deutscher Verbraucherschutz-Organisationen, u.a.
      Stiftung Warentest. Diese Organisationen definieren Mindeststandards im
      Sinne des Verbrauchers für die einzelnen Versicherungssparten.<br /><br />In
      Smart Check werden diese Kriterien zusammengefasst dargestellt. Bei
      Abweichungen zwischen den Verbraucherschutz-Organisationen wird das
      jeweils höherwertige Kriterium zur Bewertung herangezogen.
      <br />
      <br />
      @if(products) {
      <mat-accordion class="flat-card">
        @for (product of products; track product.id) {
        <mat-expansion-panel class="flat-card">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon
                mat-list-icon
                fontSet="far"
                [fontIcon]="product.icon"
                class="theme-color-primary"
              ></mat-icon>
              {{ product.name }} - {{ product.criterionCount }} Kriterien
            </mat-panel-title>
          </mat-expansion-panel-header>
          @for ( category of product.criterions; track category.id) {
          <mat-card class="flat-card">
            <mat-card-title class="colored">{{ category.name }}</mat-card-title>

            <div>
              @for (criterion of category.criterions; track criterion.id) {
              <p>
                <mat-icon
                  mat-list-icon
                  fontSet="fas"
                  fontIcon="fa-info-circle"
                  class="theme-color-primary"
                  (click)="
                    showCriterionInfo(criterion.name, criterion.aboutLong)
                  "
                ></mat-icon
                >{{ criterion.name }}
                @if (criterion.value && criterion.value !== ' ') {
                <span>-</span>
                }
                {{ criterion.value }}
              </p>
              }
            </div>
          </mat-card>
          }
        </mat-expansion-panel>
        }
      </mat-accordion>
      }
    </mat-card-content>
  </mat-card>
</main>
