import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Login, APP_CONFIG, User, CurrentUser } from '@smart-check/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url: string;
  constructor(private http: HttpClient) {
    this.url = APP_CONFIG.csApi;
  }

  getIdentity(login: Login): Observable<User> {
    const formData: FormData = new FormData();
    formData.append('userName', login.userName);
    formData.append('password', login.password);
    return this.http.post<User>(`${this.url}/login`, formData, {
      responseType: 'json',
      withCredentials: false,
    });
  }

  getSsoIdentity(ssoToken: string) {
    return this.http.get<User>(`${this.url}/enter/${ssoToken}`);
  }

  getImpersonateIdentity(ssoToken: string) {
    return this.http.get<CurrentUser>(
      `${this.url}/impersonate/enter/${ssoToken}`
    );
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }
}
