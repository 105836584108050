import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService  {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> {
    return new Promise((resolve) => {
      if (localStorage.getItem('user')) {
        resolve(true);
      } else {
        this.router.navigate(['/']);
        resolve(false);
      }
    });
  }
}
