import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogSurveyComponent } from './../dialog/dialog-survey/dialog-survey.component';
import {
  ProductType,
  ProductGroups,
  ProductService,
  UserService,
} from '@smart-check/feature-comparison';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'tariff-check-product',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
})
export class ProductSelectComponent implements OnInit {
  public productGroups: Array<ProductGroups>;
  public products: Array<ProductType>;
  constructor(
    private productService: ProductService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.productGroups = this.productService.getProductGroups();
    this.productService.getProductTypeSorted(false).subscribe({
      next: (response: Array<ProductType>) => {
        this.products = response;
      },
    });
    this.getHasSeenInfo();
  }

  getHasSeenInfo(): void {
    this.userService.getHasSeenInfo('umbuzzo24', true).subscribe({
      next: (response: string) => {
        if (response.toString() === "false") {
          this.openSurveyDialog();
        }
      },
    });
  }

  findByCategory(category: ProductType[], index: number): ProductType[] {
    return this.productService.findByCategory(category, index);
  }

  onSelectionChange($event: any) {
    this.router.navigate(['/tariffSelect', $event.options[0].value.id]);
  }

  openSurveyDialog(): void {
    this.dialog.open(DialogSurveyComponent, {});
  }
}
