import { FormGroup } from '@angular/forms';

export interface Address {
  name?: string | null;
  street?: string;
  street_number?: string;
  zipcode?: string;
  city?: string;
  state?: string | null;
  country?: string;
  telefone1?: string | null;
  telefone2?: string | null;
  email?: string | null;
}
export interface Company {
  id: number;
  slug?: string;
  is_active?: boolean;
  name?: string;
  short_name?: string;
  web?: string | null;
  product_type_ids?: Array<number> | null;
  import_id?: number | null;
  import_id_neu?: number | null;
  uuid?: string;
  fbb_id?: string | null;
  broker_id?: string | null;
  bafin_id?: string | null;
  logo?: string | null;
  logoThumb?: string | null;
  address?: Address;
  contacts?: Array<Contact> | null;
  currentCompany_id?: number | null;
}
export interface ComparisonByYearResults {
  page?: number;
  itemsPerPage?: number;
  results: Array<ComparisonResults>;
  totalItems?: number;
}
export interface ComparisonResults {
  Criteria: Array<any>;
  score_possible?: number;
  score_reached?: number;
  company_name?: string;
  company_shortname?: string;
  company_bafinid?: string;
  tarif_name?: string;
  tarif_terms?: string;
  tarif_year?: string;
  tarif_slug?: string;
}
export interface Contact {
  firstname?: string;
  middlename?: string;
  lastname?: string;
  telefone1?: string;
  telefone2?: string;
  email?: string;
  address?: Address;
  company_id?: number;
}
export interface Criterion {
  id?: number;
  about?: string;
  aboutLong?: string;
  comparisonId?: number;
  criterionId?: number;
  result?: number;
  value?: string;
  tariffId?: number;
  name?: string;
  position?: number;
  categoryName?: string;
  criterion_about?: string;
  criterion_about_long?: string;
  referenceValue?: string;
}

export interface Category {
  id?: number;
  name?: string;
  position?: number;
  criterions?: Array<Criterion>;
  isAdditionalCriterionCategory?: boolean;
  criterionCount?: number;
}

export interface Sidenav {
  id: number;
  title: string;
}
export interface Product {
  slug?: string;
  name?: string;
  isActive?: string;
}
export interface ProductResults {
  page?: number;
  perPage?: number;
  results: Array<Product>;
  totalItems?: number;
}

export interface ProductType {
  id: number;
  name?: string;
  slug?: string;
  alternative_slug?: string | null;
  import_key?: string | null;
  product_type_sparte_id?: number;
  position?: number;
  icon: string;
  reference_tariff_id: number;
  criterionCount: number;
  criterions: Array<Category>;
}
export interface Tariff {
  id: number;
  name?: string;
  title?: string;
  terms?: string;
  show_in_comparison: boolean;
  additional_information?: string | null;
  is_active: boolean;
  is_top_tariff: boolean;
  created?: string;
  updated?: string;
  slug?: string;
  terms_available: boolean;
  terms_private: boolean;
  active_since?: string;
  importId?: number | null;
  is_baustein_tariff: boolean;
  company: Company;
  product_type: ProductType;
  base_tariff: Tariff | null;
  criterions?: any;
  variant: string;
}
export interface ExclusiveTariff {
  productTypeId?: number;
  exclusiveTariffId?: number;
}
export interface TariffScore {
  score_possible?: number;
  score_reached?: number;
}
export interface ProductGroups {
  name: string;
}
export interface Variant {
  id: number;
  tariffName: string;
  tags: Array<string> | undefined;
}

export interface TariffSelection {
  currentProductType: ProductType;
  currentTariff: Tariff | undefined;
  offerTariff: Tariff | undefined;
  isOffer: boolean;
}

export interface PrintData {
  currentProductType: ProductType;
  currentTariff: Tariff | undefined;
  offerTariff: Tariff | undefined;
  printForm?: FormGroup;
}

export interface TariffMail {
  productType: string;
  company: string;
  tariffName: string;
  terms: string;
  email: string;
  file?: File;
  notice?: string;
}

export interface ComparisonMail {
  gender: string;
  name: string;
  sendToConsultant: string;
  emailConsultant: string;
  emailCustomer: string;
  productType: string;
  currentTariff: string;
  offerTariff: string;
  file: Blob;
}

export interface CompanyAccount {
  company?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  logo?: File; // For PDF
}

export interface Consumption {
  '23'?: number;
  '17'?: number;
  '21'?: number;
  '19'?: number;
  '24'?: number;
  '18'?: number;
  '8'?: number;
  '7'?: number;
  '6'?: number;
  '5'?: number;
  '4'?: number;
  '3'?: number;
  '2'?: number;
  '1'?: number;
  '22'?: number;
}

export interface ConsumptionData {
  month: number;
  checks: number;
}

export interface UserDetails {
  gender?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  netfondsemail?: string;
  phone?: string;
  position?: string;
  broker_no?: string;
  companyName?: string;
  street?: string;
  streetNumber?: string;
  zip?: string;
  city?: string;
  acceptedGdpr?: number;
  wantsWerbung?: number;
  exclusiveTariffe?: Array<ExclusiveTariff>;
  anonymous?: boolean;
}
