<mat-toolbar class="mat-toolbar mat-toolbar-single-row">
  <div>
    <a routerLink="/home"
      ><img
        class="header-logo"
        src="assets/img/logos/smartinsurtech_neg.svg"
        alt="Logo"
    /></a>
  </div>
  <div class="flex flex-1 justify-end">
    <button
      mat-button
      id="highlights"
      class="hide-xs"
      data-cy="highlights"
      (click)="scrollToElement(highlights, $event)"
    >
      Vorteile
    </button>
    <button
      mat-button
      id="licence"
      class="hide-xs"
      data-cy="licence"
      (click)="scrollToElement(licence, $event)"
    >
      Lizenz
    </button>
    <button
      mat-button
      id="rating"
      class="hide-xs"
      data-cy="rating"
      (click)="scrollToElement(rating, $event)"
    >
      Bewertung
    </button>
    <button
      mat-button
      id="contact"
      class="hide-xs"
      data-cy="contact"
      (click)="scrollToElement(contact, $event)"
    >
      Kontakt
    </button>
    <button
      mat-button
      class="hide-gt-xs"
      #menuTrigger
      [matMenuTriggerFor]="scheme"
    >
      <mat-icon fontSet="fas" fontIcon="fa-bars"></mat-icon>
    </button>
  </div>
  <mat-menu class="menu-content" #scheme="matMenu" xPosition="before">
    <button
      mat-menu-item
      data-cy="highlights"
      (click)="scrollToElement(highlights, $event)"
    >
      Vorteile
    </button>
    <button
      mat-menu-item
      data-cy="licence"
      (click)="scrollToElement(licence, $event)"
    >
      Lizenz
    </button>
    <button
      mat-menu-item
      data-cy="rating"
      (click)="scrollToElement(rating, $event)"
    >
      Bewertung
    </button>
    <button
      mat-menu-item
      data-cy="contact"
      (click)="scrollToElement(contact, $event)"
    >
      Kontakt
    </button>
  </mat-menu>
</mat-toolbar>
<main>
  <section class="headline">
    <div class="login-container">
      <div class="flex flex-1 justify-end">
        <form
          id="loginForm"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <div class="flex-row flex-col-sm align-center gap-16">
            <mat-form-field appearance="outline">
              <mat-label>Email / Benutzername</mat-label>
              <input
                matInput
                placeholder="Email / Benutzername"
                formControlName="userName"
                autocomplete="username"
                data-cy="username"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Passwort</mat-label>
              <input
                matInput
                [type]="passwordHidden ? 'password' : 'text'"
                placeholder="Password"
                formControlName="password"
                autocomplete="current-password"
                data-cy="password"
              />
              <mat-icon matSuffix (click)="passwordHidden = !passwordHidden">{{
                passwordHidden ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </mat-form-field>
            <div>
              <button
                mat-flat-button
                id="submit"
                data-cy="submit"
                mat-button
              >
                Einloggen
              </button>
            </div>
            <div>
              <button
                mat-button
                id="forgot-password"
                type="button"
                data-cy="forgot-password"
                (click)="showForgotPassword()"
              >
                Passwort vergessen
              </button>
            </div>
          </div>
          <span class="caption error">{{ responseMessage }}</span>
        </form>
      </div>
    </div>
    <div class="container">
      <div class="shapes-outer">
        <div class="shapes">
          <div class="inner-wrapper">
            <h1 class="product">
              <span>SMART<strong> Check</strong></span
              >Transparenz und Glaubwürdigkeit für den Versicherungsvergleich
            </h1>
          </div>
          <i class="smiticon --smart_check" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="flex-row flex-col-xs justify-end">
      <div class="news-container">
<!--        <div class="flex-row justify-evenly align-center">-->
<!--          <div>-->
<!--            <img src="assets/img/caution_red.png" alt="icon" style="height:100px;width:auto"/>-->
<!--          </div>-->
<!--          <div>-->
<!--            <h3 style="color:red">HINWEIS</h3>-->
<!--            <p>-->
<!--              <strong>-->
<!--                E-Mail Funktionen momentan eingestellt.-->
<!--              </strong>-->
<!--            </p>-->
<!--            <p>Aktuell können auf Grund technischer Probleme keine Test-Accounts angefordert und Passwörter zurückgesetzt werden.<br>-->
<!--              Wir arbeiten mit Hochdruck an der Behebung des Problems. Vielen Dank für Ihr Verständnis</p>-->
<!--          </div>-->
<!--       </div>-->
        <div class="flex-row justify-evenly align-center">
          <div>
            <img src="assets/img/kfz.svg" alt="icon" style="height:100px;width:auto"/>
          </div>
          <div>
            <h3>NEU ab November 2024</h3>
            <p>
              <strong>
                Zusatzkriterien für E- oder Hybrid Fahrzeuge in der KFZ-Versicherung.
              </strong>
            </p>
            <p>15 neue Leistungskriterien zur Tarifbewertung und Tarifvergleich.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="divider1">
    <h2 class="divider-title">
      Qualitätsbewertung von Alt- und Neutarifen. Verbraucherschutzkonform.
      Unabhängig. Objektiv.<br />
      Optimal für Kundenberatung und Neukundengewinnung.
    </h2>
  </div>
  <section class="highlights">
    <div #highlights class="container">
      <div class="title-container" data-cy="highlights-title">
        <h5>IHRE VORTEILE IM ÜBERBLICK</h5>
      </div>
      <h1 class="section-title">
        Unabhängige Tarifbewertung nach Verbraucherschutzkriterien
      </h1>
      <div class="flex-row flex-col-md align-center gap-16">
        <div class="flex-grow">
          <div class="highlights-container">
            <div class="highlights-title" data-cy="highlights-title">
              <span class="accent">Highlights</span> auf einen Blick
            </div>
            <p>
              <i class="fa fa-check" aria-hidden="true"></i> Bewertungs- und
              Vergleichsmöglichkeit von Bestandsverträgen, bzw. älteren
              Bedingungswerken
            </p>
            <p>
              <i class="fa fa-check" aria-hidden="true"></i> Pdf-Dokumentation
              des Vergleichs mit automatischer Versandoption per E-Mail
            </p>
            <p>
              <i class="fa fa-check" aria-hidden="true"></i> Bietet maximale
              Zeitersparnis, Qualität und Transparenz
            </p>
            <p>
              <i class="fa fa-check" aria-hidden="true"></i> Einsetzbar in
              Bestandspflege und Neukundengewinnung
            </p>
            <p>
              <i class="fa fa-check" aria-hidden="true"></i>
              Verbraucherschutzkonform und absolut unabhängig
            </p>
            <p>
              <i class="fa fa-check" aria-hidden="true"></i> Auch mobil auf
              Smartphone oder Tablet einsetzbar
            </p>
          </div>
          <div class="test-container">
            <div class="flex-row flex-col-md align-center gap-24">
              <div>
                <h3>
                  Sichern Sie sich Ihren kostenlosen 14-tägigen Testaccount
                </h3>
              </div>
              <div>
                <button
                  mat-flat-button
                  id="cta-button"
                  class="cta-button"
                  data-cy="cta-button"
                  (click)="showTestAccount()"
                >
                  Hier klicken
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="screenshot-container flex-grow">
          <img
            class="responsive tablet"
            src="assets/img/mediamodifier_image.png"
            alt="smartcheck screenshot"
          />
          <p class="text-label">
            Beantworten Sie die Frage <em>"Bin ich richtig versichert?"</em> und
            überzeugen Sie Ihren Kunden von Ihrer Beratung. <br />
            Mit nur wenigen Klicks, anschaulich und übersichtlich visualisiert,
            einfach und verständlich erklärt.
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="divider2">
    <h1 class="divider-title">Transparenz für Ihren Kunden</h1>
  </div>
  <section class="licence">
    <div #licence class="container">
      <div class="title-container">
        <h5 data-cy="licence-title">Lizenz</h5>
      </div>
      <h1 class="section-title">
        Folgende Lizenz-Varianten stehen Ihnen zur Verfügung
      </h1>
      <div class="flex-row flex-col-md justify-between gap-64">
        <div class="flex-grow licence-container sach">
          <div class="head">
            <div class="licence-title">
              <span class="package">Basis-Paket I - </span>Sachversicherung
            </div>
          </div>
          <div class="product-container flex-col justify-between">
            <div>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Privathaftpflichtversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Hausratversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Wohngebäudeversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Unfallversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Kfz
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Rechtsschutzversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Tierhalterversicherung (Hund und Pferd)
              </p>
            </div>
            <div>
              <div class="flex-row flex-col-xs justify-between gap-8">
                <div class="flex-grow">
                  <small class="payment">Lizenzgebühr mtl. Zahlweise</small>
                  <br />
                  <h2 class="price">35 <span class="eur">€</span></h2>
                </div>
                <div class="flex-grow">
                  <small class="payment">Lizenzgebühr jährl. Zahlweise</small
                  ><br />
                  <h2 class="price">384 <span class="eur">€</span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow licence-container krank">
          <div class="head">
            <div class="licence-title">
              <span class="package">Paket II - </span>Krankenversicherung
            </div>
            <div class="small-container">
              <small>nur in Verbindung mit Basis-Paket I</small>
            </div>
          </div>
          <div class="product-container flex-col justify-between">
            <div>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Private Krankenvollversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Zahnzusatzversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Stationäre Zusatzversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Krankentagegeld
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Pflegezusatzversicherung
              </p>
            </div>
            <div>
              <div class="flex-row flex-col-xs justify-between gap-8">
                <div class="flex-grow">
                  <small class="payment">Lizenzgebühr mtl. Zahlweise</small>
                  <br />
                  <h2 class="price">25 <span class="eur">€</span></h2>
                </div>
                <div class="flex-grow">
                  <small class="payment">Lizenzgebühr jährl. Zahlweise</small
                  ><br />
                  <h2 class="price">274 <span class="eur">€</span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow licence-container leben">
          <div class="head">
            <div class="licence-title">
              <span class="package">Paket III - </span>Risikovorsorge
            </div>
            <div class="small-container">
              <small>nur in Verbindung mit Basis-Paket I</small>
            </div>
          </div>
          <div class="product-container flex-col justify-between">
            <div>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Berufsunfähigkeitsversicherung
              </p>
              <p>
                <i class="fa fa-check" aria-hidden="true"></i>
                Risikolebensversicherung
              </p>
            </div>
            <div>
              <div class="flex-row flex-col-xs justify-between gap-8">
                <div class="flex-grow">
                  <small class="payment">Lizenzgebühr mtl. Zahlweise</small>
                  <br />
                  <h2 class="price">10 <span class="eur">€</span></h2>
                </div>
                <div class="flex-grow">
                  <small class="payment">Lizenzgebühr jährl. Zahlweise</small
                  ><br />
                  <h2 class="price">98 <span class="eur">€</span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>
        1 Berater (Hauptlizenz). Alle Preise sind Nettopreise zzgl. der
        gesetzlichen MwSt.
      </p>
      <div class="discount-container flex-row flex-col-xs gap-16">
        <div class="justify-center">
          <i class="fa-3x fa fa-piggy-bank" aria-hidden="true"></i>
        </div>
        <div class="justify-center">
          <h3>Rabatt-Möglichkeiten</h3>
          <span
            >Bündelrabatt - 10% (Buchung Paket <span class="packageI">I</span> +
            <span class="packageII">II</span> +
            <span class="packageIII">III</span>)</span
          >
        </div>
      </div>
      <div class="pdf-container">
        Wenn Sie SMART CHECK zukünftig nutzen möchten, füllen Sie das
        <a
          class="pdf-link"
          href="#"
          onclick="window.open('assets/doc/Lizenzvertrag_02_2024.pdf', '_blank', 'fullscreen=yes'); return false;"
          >pdf
          <i class="fa fa-arrow-up-right-from-square" aria-hidden="true"></i
        ></a>
        aus und schicken Sie es per E-Mail an
        <a class="mail-link" href="mailto:info&#64;tarifair.de"
          >info&#64;tarifair.de</a
        >
      </div>
    </div>
  </section>
  <div class="divider3">
    <h1 class="divider-title">Verbraucherschutzkonform</h1>
  </div>
  <section class="rating">
    <div #rating class="container">
      <div class="title-container" data-cy="rating-title">
        <h5>Bewertung</h5>
      </div>
      <h1 class="section-title">
        Wie wird die Qualität eines Versicherungstarifs bewertet?
      </h1>
      <div class="rating-text">
        <p>
          Die Qualitätsbewertung und der Vergleich von Versicherungsbedingungen
          mit <strong class="accent">SMART CHECK</strong> basiert auf den
          Leistungskriterien deutscher Verbraucherschutz-Organisationen, u.a.
          Stiftung Warentest. Diese Organisationen definieren Mindeststandards
          im Sinne des Verbrauchers für die einzelnen Versicherungssparten.
        </p>
        <p>
          In <strong class="accent">SMART CHECK</strong> werden diese Kriterien
          zusammengefasst dargestellt. Bei Abweichungen zwischen den
          Verbraucherschutz-Organisationen wird das jeweils höherwertige
          Kriterium zur Bewertung herangezogen.
        </p>
        <p>
          Mit <strong class="accent">SMART CHECK</strong> können folgende
          Versicherungsprodukte bewertet und verglichen werden.
        </p>
        <p>
          <strong>Sachversicherung:</strong> Privathaftpflichtversicherung,
          Kfz-Versicherung, Hausratversicherung, Wohngebäudeversicherung,
          Rechtsschutzversicherung, Unfallversicherung, Tierhalterversicherung
          (Hund und Pferd)

          <br />
          <strong>Krankenversicherung:</strong> Private Krankenvollversicherung,
          Zahnzusatzversicherung, Stationäre Zusatzversicherung,
          Krankentagegeld, Pflegezusatzversicherung

          <br />
          <strong>Risikovorsorge:</strong> Berufsunfähigkeitsversicherung,
          Risikolebensversicherung
        </p>
      </div>
      <div class="flex-row flex-col-md justify-between">
        <div class="donut-container">
          <div class="rating-title">Top-Tarif: alle Kriterien erfüllt</div>
          <img
            class="responsive donut"
            src="assets/img/donut.png"
            alt="donut top-tarif"
          />
        </div>
        <div class="criteria-container">
          <div class="rating-title">
            Beispiele für Kriterien der SMART CHECK Qualitätsbewertung
          </div>
          <div class="mat-card rating-card">
            <mat-card-title
              >Hausratversicherung - Versicherungssummen (VS)</mat-card-title
            ><mat-card-content _ngcontent-krh-c283="" class="mat-card-content"
              ><p>
                <i
                  id="info-circle"
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  (click)="
                    showCriterionInfo(
                      'Wertsachen',
                      'Wertsachen, dazu zählen u.a., Bargeld, Urkunden, Sparbücher und Wertpapiere, Schmuck, Edelsteine, Perlen und alle Gegenstände aus Gold, Platin oder Silber, Briefmarken, Münzen, Telefonkarten und Medaillen, Pelze, handgeknüpfte Teppiche und Gobelins sowie Kunstgegenstände wie Gemälde, Zeichnungen, Grafiken, Plastiken und Collagen, sind im genannten Umfang mitversichert.'
                    )
                  "
                ></i>
                Wertsachen
                <span>-</span>
                mind. 40% der Versicherungssumme
              </p>
              <p>
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  (click)="
                    showCriterionInfo(
                      'Bargeld etc.',
                      'Außerhalb von anerkannten und verschlossenen Wertschutzschränken ist Bargeld bis zum genannten Betrag mitversichert.'
                    )
                  "
                ></i>
                Bargeld etc.
                <span>-</span>
                mind. 2.000 €
              </p>
              <p>
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  (click)="
                    showCriterionInfo(
                      'Urkunden, Sparbücher etc.',
                      'Außerhalb von anerkannten und verschlossenen Wertschutzschränken sind Urkunden, Sparbücher etc. bis zum genannten Betrag mitversichert.'
                    )
                  "
                ></i>
                Urkunden, Sparbücher etc.
                <span>-</span>
                mind. 5.000 €
              </p>
              <p>
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  (click)="
                    showCriterionInfo(
                      'Schmucksachen, Edelsteine etc.',
                      'Außerhalb von anerkannten und verschlossenen Wertschutzschränken sind Schmuck, Edelsteine, Briefmarken, Münzen etc. bis zum genannten Betrag mitversichert.'
                    )
                  "
                ></i>
                Schmucksachen, Edelsteine etc.
                <span>-</span>
                mind. 30.000 €
              </p>
              <p>
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  (click)="
                    showCriterionInfo(
                      'Außenversicherung',
                      'Außerhalb des Versicherungsortes besteht im genannten Umfang Versicherungsschutz.'
                    )
                  "
                ></i>
                Außenversicherung
                <span>-</span>
                mind. 20% der Versicherungssumme, mind. 6 Monate
              </p>
              <p>
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  (click)="
                    showCriterionInfo(
                      'Vorsorge',
                      'Die vereinbarte Versicherungssumme erhöht sich um den genannten Vorsorgebetrag.'
                    )
                  "
                ></i>
                Vorsorge
                <span>-</span>
                mind. 10% der Versicherungssumme
              </p>
            </mat-card-content>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="divider4">
    <h1 class="divider-title">transparent · objektiv · unabhängig</h1>
  </div>
  <section class="contact">
    <div #contact class="container">
      <div class="content">
        <div class="title-container" data-cy="contact-title">
          <h5>Kontakt</h5>
        </div>
        <h1 class="section-title">Haben Sie noch Fragen?</h1>

        <div class="fill flex-row flex-col-md align-center gap-64">
          <div class="img-container">
            <img
              class="responsive"
              src="assets/img/smit-web-header-kontakt-scaled.jpg"
              alt="smartcheck kontakt"
            />
          </div>
          <div class="txt-container">
            <p>
              Wir freuen uns, Ihnen individuell zu helfen und <br />
              direkt Lösungsvorschläge zu machen.
            </p>
            <p>Jetzt unverbindlich anfragen.<br />Wir beraten Sie gerne.</p>
            <p>
              <strong
                >Sie erreichen uns telefonisch von Mo - Do in der Zeit von 09:00h - 17:00h und Fr von 09:00h - 15:00h
                <br />
                Telefon: +49 30 233237-0 oder E-Mail:
                <a class="tertiary" href="mailto:info&#64;tarifair.de"
                  >info&#64;tarifair.de</a
                ></strong
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="footer">
    <div class="container">
      <div class="flex-row flex-col-md fill">
        <div class="logo-container">
          <img
            class="footer-logo"
            src="assets/img/logos/smartinsurtech_neg.svg"
            alt="Logo"
          />
        </div>
        <div class="address-container">
          <p>Heidestraße 8</p>
          <p>10557 Berlin</p>
          <p>Tel. +49 30 233237-0</p>
          <p>Mail: info&#64;tarifair.de</p>
          <p>Web: https://www.smartinsurtech.de</p>
        </div>
        <div class="flex-grow link-container">
          <div>
            <button
              mat-button
              onclick="window.open('assets/doc/smit-allgemeine_geschaeftsbedingungen.pdf', '_blank', 'fullscreen=yes'); return false;"
            >
              Geschäftsbedingungen</button
            ><button
              mat-button
              onclick="window.open('assets/doc/Impressum.pdf', '_blank', 'fullscreen=yes'); return false;"
            >
              Impressum</button
            ><button
              mat-button
              onclick="window.open('assets/doc/privacy.pdf', '_blank', 'fullscreen=yes'); return false;"
            >
              Datenschutz
            </button>
          </div>
          <p class="copyright">
            Smart InsurTech AG ©2014-{{ currentYear }} -- Made with
            <i class="fa fa-regular fa-heart" aria-hidden="true"></i> in
            Heidelberg
          </p>
          <a
            title="linkedin"
            href="https://www.linkedin.com/company/smart-insurtech"
            target="_blank"
            rel="noopener"
          >
            <img src="assets/img/linkedin.svg" alt="linkedin link"
          /></a>
          <a
            title="facebook"
            href="https://www.facebook.com/SmartInsurTechAG"
            target="_blank"
            rel="noopener"
          >
            <img src="assets/img/facebook.svg" alt="facebook link"
          /></a>
          <a
            title="youtube"
            href="https://www.youtube.com/c/SmartInsurTechAG"
            target="_blank"
            rel="noopener"
          >
            <img src="assets/img/youtube.svg" alt="youtube link"
          /></a>
          <a
            title="twitter"
            href="https://twitter.com/SmartInsurTech"
            target="_blank"
            rel="noopener"
          >
            <img src="assets/img/twitter.svg" alt="twitter link"
          /></a>
          <a
            title="xing"
            href="https://www.xing.com/companies/smartinsurtechag"
            target="_blank"
            rel="noopener"
          >
            <img src="assets/img/xing.svg" alt="xing link"
          /></a>
        </div>
      </div>
    </div>
  </div>
</main>
