import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  NotificationService,
  AuthService,
  CurrentUser,
} from '@smart-check/shared/ui';
import {
  UserService,
  ProductService,
  UserDetails,
} from '@smart-check/feature-comparison';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ChartConfiguration, ChartType, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public user: CurrentUser = JSON.parse(localStorage.getItem('user')!);
  public year: number = new Date().getFullYear();
  public currentYear: number = new Date().getFullYear();
  public userDetails: UserDetails;
  public userAccountForm: FormGroup = this.formBuilder.group({});
  public companyAccountForm: FormGroup = this.formBuilder.group({});
  public passwordAccountForm: FormGroup = this.formBuilder.group({});
  public scheme = localStorage.getItem('scheme')!;
  public logo: string | ArrayBuffer | null;
  public isLogoLoaded: boolean;
  public checks: any;
  public consumptionArray: any = [];
  public currentPasswordHidden: boolean = true;
  public newPasswordHidden: boolean = true;
  public newPasswordRepeatHidden: boolean = true;
  public barChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        backgroundColor: 'rgba(148,159,177,0.5)',
        borderColor: 'rgba(148,159,177,1)',
        borderWidth: 3,
        borderSkipped: 'bottom',
        borderRadius: 6,
        fill: 'origin',
      },
    ],
    labels: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  };
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    layout: {
      padding: 0,
    },
    scales: {
      x: {
        ticks: {
          padding: 15,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'start',
        align: 'start',
      },
    },
  };

  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];

  // Pie
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
  };
  public pieChartType: ChartType = 'pie';

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private injector: Injector,
    private authService: AuthService,
    private productService: ProductService
  ) {
    this.userAccountForm = new FormGroup({
      genderControl: new FormControl(''),
      firstNameControl: new FormControl(''),
      lastNameControl: new FormControl(''),
      emailControl: new FormControl(''),
      phoneControl: new FormControl(''),
      positionControl: new FormControl(''),
      broker_noControl: new FormControl(''),
    });
    this.companyAccountForm = new FormGroup({
      companyNameControl: new FormControl(''),
      streetControl: new FormControl(''),
      streetNumberControl: new FormControl(''),
      zipControl: new FormControl(''),
      cityControl: new FormControl(''),
    });
    this.passwordAccountForm = new FormGroup({
      currentPasswordControl: new FormControl('', [Validators.required]),
      newPasswordControl: new FormControl('', [Validators.required]),
      newPasswordRepeatControl: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getLogo();

    this.userService.getUserDetails().subscribe({
      next: (response: UserDetails) => {
        this.userDetails = response;
      },
      complete: () => {
        if (!this.userDetails.anonymous) {
          this.userAccountForm.controls['genderControl'].setValue(
            this.userDetails.gender
          );
          this.userAccountForm.controls['firstNameControl'].setValue(
            this.userDetails.firstName
          );
          this.userAccountForm.controls['lastNameControl'].setValue(
            this.userDetails.lastName
          );
          this.userAccountForm.controls['emailControl'].setValue(
            this.userDetails.email
          );
          this.userAccountForm.controls['phoneControl'].setValue(
            this.userDetails.phone
          );
          this.userAccountForm.controls['positionControl'].setValue(
            this.userDetails.position
          );
          this.userAccountForm.controls['broker_noControl'].setValue(
            this.userDetails.broker_no
          );
          this.companyAccountForm.controls['companyNameControl'].setValue(
            this.userDetails.companyName
          );
          this.companyAccountForm.controls['streetControl'].setValue(
            this.userDetails.street
          );
          this.companyAccountForm.controls['streetNumberControl'].setValue(
            this.userDetails.streetNumber
          );
          this.companyAccountForm.controls['zipControl'].setValue(
            this.userDetails.zip
          );
          this.companyAccountForm.controls['cityControl'].setValue(
            this.userDetails.city
          );
        }
      },
    });

    this.userService.getConsumption('0000-00-00', '9999-12-31').subscribe({
      next: (response: Object) => {
        this.pieChartData.datasets[0].data = Object.values(response);
        this.pieChartData.labels = Object.keys(response);
        for (let index = 0; index < this.pieChartData.labels.length; index++) {
          this.pieChartData.labels[index] =
            this.productService.getProductShortName(
              Number(this.pieChartData.labels[index])
            ) +
            ' - ' +
            this.pieChartData.datasets[0].data[index];
        }
      },
    });
    this.getConsumptionYearly(this.year);
  }

  getLogo() {
    this.userService.getUserLogo().subscribe({
      next: (response: Blob) => {
        this.createImageFromBlob(response);
        this.isLogoLoaded = true;
      },
      error: () => {
        this.isLogoLoaded = false;
      },
    });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.logo = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getConsumptionYearly(year: number): void {
    this.barChartData.datasets[0].data.length = 0;
    this.year = year;
    this.userService.getConsumptionYearly(year).subscribe({
      next: (response: Array<{ month: number; checks: number }>) => {
        if (response.hasOwnProperty(11)) {
          for (let i = 0; i < 12; i++) {
            this.barChartData.datasets[0].data.push(response[i].checks);
          }
        }
        this.chart?.update();
      },
    });
  }

  focusOutCurrentPassword() {
    if (this.passwordAccountForm.get('currentPasswordControl')?.valid) {
      const password = this.passwordAccountForm.get(
        'currentPasswordControl'
      )?.value;
      this.userService.checkPassword(password).subscribe({
        next: (response: any) => {
          if (response !== 0) {
            this.passwordAccountForm
              .get('currentPasswordControl')
              ?.setErrors({ incorrect: true });
          }
        },
      });
    }
  }

  focusOutNewPassword() {
    const password = this.passwordAccountForm.get('newPasswordControl')?.value;
    const confirmPassword = this.passwordAccountForm.get(
      'newPasswordRepeatControl'
    )?.value;

    if (password !== confirmPassword) {
      this.passwordAccountForm
        .get('newPasswordRepeatControl')
        ?.setErrors({ incorrect: true });
    }
  }

  back(): void {
    this.location.back();
  }

  saveUserAccount(userAccountForm: FormGroup, companyAccountForm: FormGroup) {
    const userDetails: UserDetails = {
      gender: userAccountForm.get('genderControl')?.value,
      firstName: userAccountForm.get('firstNameControl')?.value,
      lastName: userAccountForm.get('lastNameControl')?.value,
      email: userAccountForm.get('emailControl')?.value,
      phone: userAccountForm.get('phoneControl')?.value,
      position: userAccountForm.get('positionControl')?.value,
      broker_no: userAccountForm.get('broker_noControl')?.value,
      companyName: companyAccountForm.get('companyNameControl')?.value,
      street: companyAccountForm.get('streetControl')?.value,
      streetNumber: companyAccountForm.get('streetNumberControl')?.value,
      zip: companyAccountForm.get('zipControl')?.value,
      city: companyAccountForm.get('cityControl')?.value,
    };
    this.userService.setUserDetails(userDetails).subscribe({
      next: (response) => {
        if (response === 0) {
          const notifier = this.injector.get(NotificationService);
          notifier.showSuccess('Ihr Account wurde erfolgreich aktualisiert.');
        }
      },
      complete: () => {
        this.back();
      },
    });
  }

  savePasswordAccount(passwordAccountForm: FormGroup) {
    if (this.passwordAccountForm.valid) {
      const newPw: string = passwordAccountForm.get(
        'newPasswordRepeatControl'
      )?.value;
      const oldPw: string = passwordAccountForm.get(
        'currentPasswordControl'
      )?.value;
      this.userService.changePassword(newPw, oldPw).subscribe({
        next: (response: Object) => {
          if (response === 0) {
            this.logout();
          } else if (response === 2) {
            this.passwordAccountForm
              .get('currentPasswordControl')
              ?.setErrors({ incorrect: true });
          }
        },
      });
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file && file.size < 5000000) {
      this.userService.uploadLogo(file).subscribe({
        next: (response) => {
          if (response === 0) {
            this.getLogo();
          }
        },
      });
    } else if (file && file.size > 5000000) {
      const notifier = this.injector.get(NotificationService);
      notifier.showError(
        'Die maximal zulässige Größe von 5MB wurde überschritten.'
      );
    }
  }

  removeUserLogo() {
    this.userService.removeUserLogo().subscribe({
      next: (response) => {
        if (response === 0) {
          this.getLogo();
          const notifier = this.injector.get(NotificationService);
          notifier.showSuccess('Das Standard Logo wurde wiederhergestellt');
        }
      },
    });
  }

  logout() {
    const notifier = this.injector.get(NotificationService);
    notifier.showSuccess(
      'Ihr Passwort wurde erfolgreich geändert. Sie werden abgemeldet.'
    );
    setTimeout(() => {
      this.authService.logout();
    }, 5000);
  }
}
