import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  public isMobil: boolean = false;
  constructor(private responsive: BreakpointObserver) {}

  onResize(): boolean {
    this.responsive
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobil = true;
        }
      });

    return this.isMobil;
  }
}
