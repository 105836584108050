<div mat-dialog-title>
  <span>Sie haben Ihr Passwort vergessen?</span>
</div>
<form [formGroup]="requestPasswordForm" novalidate>
  <mat-dialog-content>
    <div class="info-box">
      <div class="flex-row justify-between gap-8">
        <div><i class="fa-2x far fa-info-circle" aria-hidden="true"></i></div>
        <div>
          Anhand Ihrer Emailadresse können Sie ein neues Passwort vergeben.
          <br />
          Im Anschluss Sie erhalten eine E-Mail mit einem Link, um Ihr Passwort
          neu zu setzen. <br />
          Bitte wenden Sie sich an unseren Support, falls Sie ihre Zugangsdaten
          vergessen haben oder keinen Zugriff auf Ihr hinterlegtes Email-Konto
          haben.
        </div>
      </div>
    </div>

    <div class="flex-row flex-col-sm gap-16 gap-0-sm">
      <mat-form-field appearance="outline" class="flex-grow">
        <mat-label>E-Mail</mat-label>
        <input
          matInput
          type="email"
          placeholder="E-Mail"
          formControlName="email"
          data-cy="email"
        />
        @if(!requestPasswordForm.get('email')?.errors?.['required'] &&
        requestPasswordForm.get('email')?.errors?.['pattern']) {
        <mat-error> Bitte geben Sie eine gültige E-Mail Adresse ein </mat-error>
        } @if(requestPasswordForm.get('email')?.invalid &&
        (requestPasswordForm.get('email')?.dirty ||
        requestPasswordForm.get('email')?.touched) &&
        requestPasswordForm.get('email')?.errors?.['required']) {
        <mat-error> E-Mail ist erforderlich </mat-error>
        }
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      data-cy="close-button"
      mat-dialog-close
      (click)="onNoClick()"
    >
      Schließen
    </button>
    <button
      mat-flat-button
      [disabled]="!requestPasswordForm.valid"
      (click)="requestPassword(requestPasswordForm)"
      data-cy="password-submit"
      mat-button
    >
      Passwort zurücksetzen
    </button>
  </mat-dialog-actions>
</form>
