import { Mail } from '@smart-check/shared/ui';

const mockMailParams: Mail = {
  name: 'Max Mustermann',
  email: 'max.mustermann@test.de',
};

const mockMailResponseSuccess = {
  result: 1234,
};

const mockMailResponseWarning = {
  result: -2,
};

const mockMailResponseError = {
  result: -1,
};

export {
  mockMailParams,
  mockMailResponseSuccess,
  mockMailResponseWarning,
  mockMailResponseError,
};
