import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeExtraCrit' })
export class RemoveExtraCritPipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace('(Zusatzkriterien)', '');
    return value;
  }
}

@Pipe({ name: 'removeAG' })
export class RemoveAGPipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace('Aktiengesellschaft', 'AG');
    return value;
  }
}
