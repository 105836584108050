import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, Mail } from '@smart-check/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private url: string;
  constructor(private http: HttpClient) {
    this.url = APP_CONFIG.csApi;
  }

  sendMail(mail: Mail): Observable<Object> {
    return this.http.post<object>(`${this.url}/ajax/CreateTestUser`, mail, {
      responseType: 'json',
      withCredentials: false,
    });
  }
}
