import { Criterion } from './../models/objects.models';
import { Category } from '@smart-check/feature-comparison';

const mockTariffCriterions: any = {
  criterions: {
    Criteria: {
      Deckungssummen: [
        {
          name: 'Deckungssumme für Personen- und Sachschäden',
          value: '3 Mio. €',
          result: 2,
        },
        {
          name: 'Vermögensschäden',
          value: '15 Mio. €',
          result: 1,
        },
      ],
    },
  },
};

const mockCriterion: Criterion = {
  id: 14,
  name: 'Deckungssumme für Personen- und Sachschäden',
  about:
    'Die Deckungssumme für Personen- und Sachschäden entspricht der maximalen Entschädigung, die der Versicherer pro Schadensereignis leistet.',
  aboutLong:
    'Die Deckungssumme für Personen- und Sachschäden entspricht der maximalen Entschädigung, die der Versicherer pro Schadensereignis leistet.',
  value: 'mind. 15 Mio. €',
  position: 201,
};

const mockCriterionsArray: Array<Category> = [
  {
    name: 'Deckungssummen',
    position: 31,
    criterions: [
      {
        id: 14,
        name: 'Deckungssumme für Personen- und Sachschäden',
        about:
          'Die Deckungssumme für Personen- und Sachschäden entspricht der maximalen Entschädigung, die der Versicherer pro Schadensereignis leistet.',
        aboutLong:
          'Die Deckungssumme für Personen- und Sachschäden entspricht der maximalen Entschädigung, die der Versicherer pro Schadensereignis leistet.',
        value: 'mind. 15 Mio. €',
        position: 201,
      },
      {
        id: 15,
        name: 'Vermögensschäden',
        about:
          'Die Deckungssumme für Vermögensschäden entspricht der maximalen Enschädigung, die der Versicherer pro Schadenereignis leistet.  Ein (echter) Vermögensschaden ist ein Schaden, welcher weder durch einen Personen- noch einen Sachschaden entstanden ist.',
        aboutLong:
          'Die Deckungssumme für Vermögensschäden entspricht der maximalen Enschädigung, die der Versicherer pro Schadenereignis leistet.  Ein (echter) Vermögensschaden ist ein Schaden, welcher weder durch einen Personen- noch einen Sachschaden entstanden ist.',
        value: 'mind. 15 Mio. €',
        position: 202,
      },
      {
        id: 16,
        name: 'Forderungsausfall',
        about:
          'Die Forderungsausfall-Versicherung oder Ausfalldeckung deckt Forderungen gegen einen zahlungsunfähigen Schadenverursacher. Z. B. durch Fehlen einer eigenen Privathaftpflichtversicherung.',
        aboutLong:
          'Die Forderungsausfall-Versicherung oder Ausfalldeckung deckt Forderungen gegen einen zahlungsunfähigen Schadenverursacher. Z. B. durch Fehlen einer eigenen Privathaftpflichtversicherung.',
        value: 'ohne Mindestschadenhöhe, kein Selbstbehalt',
        position: 203,
      },
      {
        id: 606,
        name: 'Forderungsausfall, auch wenn der Schädiger in seiner Eigenschaft als Halter oder Hüter von Hunden oder Pferden haftet.',
        about:
          'Die Forderungsausfall-Versicherung oder Ausfalldeckung deckt Forderungen der mitversicherten Person gegen einen zahlungsunfähigen Schadenverursacher, sofern dieser in seiner Eigenschaft als Halter oder Hüter von Hunden oder Pferden, einen Schaden zugefügt',
        aboutLong:
          'Die Forderungsausfall-Versicherung oder Ausfalldeckung deckt Forderungen der mitversicherten Person gegen einen zahlungsunfähigen Schadenverursacher, sofern dieser in seiner Eigenschaft als Halter oder Hüter von Hunden oder Pferden, einen Schaden zugefügt hat.',
        value: 'mitversichert',
        position: 205,
      },
      {
        id: 678,
        name: 'Forderungsausfall, auch wenn der Schädiger vorsätzlich handelt',
        about:
          'Die Forderungsausfall-Versicherung oder Ausfalldeckung deckt Forderungen der mitversicherten Person gegen einen zahlungsunfähigen Schadenverursacher, selbst wenn dieser den Schaden vorsätzlich herbeigeführt hat.',
        aboutLong:
          'Die Forderungsausfall-Versicherung oder Ausfalldeckung deckt Forderungen der mitversicherten Person gegen einen zahlungsunfähigen Schadenverursacher, selbst wenn dieser den Schaden vorsätzlich herbeigeführt hat.',
        value: 'mitversichert',
        position: 207,
      },
      {
        id: 17,
        name: 'Vorsorgeversicherung für Personen- und Sachschäden',
        about:
          'Personen- und Sachschäden durch neu hinzukommende Risiken sind vorläufig bis zum genannten Betrag beitragsfrei mitversichert.',
        aboutLong:
          'Personen- und Sachschäden durch neu hinzukommende Risiken sind vorläufig bis zum genannten Betrag beitragsfrei mitversichert.',
        value: 'mind. 3 Mio. €',
        position: 209,
      },
      {
        id: 18,
        name: 'Vorsorgeversicherung für Vermögensschäden',
        about:
          'Vermögensschäden durch neu hinzukomme Risiken sind vorläufig bis zum genannten Betrag beitragsfrei mitversichert.',
        aboutLong:
          'Vermögensschäden durch neu hinzukomme Risiken sind vorläufig bis zum genannten Betrag beitragsfrei mitversichert.',
        value: 'mind. 50.000 €',
        position: 211,
      },
      {
        id: 19,
        name: 'Versicherungssumme für Mietsachschäden an gemieteten Räumen in Gebäuden',
        about:
          'Schäden an gemieteten Gebäudeteilen, z. B.  in Mietwohnungen, Mietshäusern oder Ferienwohnungen sind bis zum genannten Betrag mitversichert.',
        aboutLong:
          'Schäden an gemieteten Gebäudeteilen, z. B.  in Mietwohnungen, Mietshäusern oder Ferienwohnungen sind bis zum genannten Betrag mitversichert.',
        value: 'mind. 1 Mio. €',
        position: 213,
      },
    ],
  },
  {
    name: 'Mitversicherte Personen',
    position: 32,
    criterions: [
      {
        id: 20,
        name: 'Deliktunfähige Kinder',
        about:
          'Kinder bis zur Vollendung des 7. Lebensjahres (im Straßenverkehr auch teilweise bis zum 10. Lebensjahr) können nicht selbst haftbar gemacht werden. Liegt keine Aufsichtspflichtverletzung der Eltern vor, hat der Geschädigte somit auch keinen Anspruch auf S',
        aboutLong:
          'Kinder bis zur Vollendung des 7. Lebensjahres (im Straßenverkehr auch teilweise bis zum 10. Lebensjahr) können nicht selbst haftbar gemacht werden. Liegt keine Aufsichtspflichtverletzung der Eltern vor, hat der Geschädigte somit auch keinen Anspruch auf Schadensersatz. Sind jedoch Schäden durch deliktunfähige Kinder mitversichert, ersetzt der Versicherer auf Wunsch des Versicherungsnehmers den Schaden bis zum genannten Betrag.',
        value: 'mind. 20.000 €',
        position: 215,
      },
      {
        id: 22,
        name: 'Sofern über den Vertrag Kinder mitversichert sind: Betriebspraktika von Schülern und eine Wartezeit bis zu einem Jahr bis zum Beginn einer Ausbildung oder eines Wehrdienstes',
        about:
          'Schäden im Rahmen eines Betriebspraktikums sind mitversichert. Der Versicherer kann sich nicht auf den Ausschluss "berufliches Risiko" berufen.',
        aboutLong:
          'Schäden im Rahmen eines Betriebspraktikums sind mitversichert. Der Versicherer kann sich nicht auf den Ausschluss "berufliches Risiko" berufen.',
        value: 'mitversichert',
        position: 217,
      },
      {
        id: 23,
        name: 'Regressansprüche von Sozialversicherungsträgern',
        about:
          'Gemäß Paragraph 116 SGB X (Änderung zum 01.01.2021) besteht für die Sozialversicherer keine Regressmöglichkeit mehr bei Personen, die in häuslicher Gemeinschaft mit dem Versicherungsnehmer leben.',
        aboutLong:
          'Gemäß Paragraph 116 SGB X (Änderung zum 01.01.2021) besteht für die Sozialversicherer keine Regressmöglichkeit mehr bei Personen, die in häuslicher Gemeinschaft mit dem Versicherungsnehmer leben.',
        value: 'mitversichert',
        position: 219,
      },
    ],
  },
  {
    name: 'Tiere',
    position: 33,
    criterions: [
      {
        id: 24,
        name: 'Privates Hüten fremder Hunde',
        about:
          'Schäden, die beim Aufpassen auf einen fremden Hund entstehen, sind mitversichert. Z. B. der Hund rennt über die Straße und verursacht einen Unfall.',
        aboutLong:
          'Schäden, die beim Aufpassen auf einen fremden Hund entstehen, sind mitversichert. Z. B. der Hund rennt über die Straße und verursacht einen Unfall.',
        value: 'mitversichert (unabhängig von der Rasse)',
        position: 221,
      },
      {
        id: 558,
        name: 'Hüten fremder Pferde (ohne Ansprüche der Halter und Eigentümer von Pferd)',
        about:
          'Schäden, die beim Aufpassen auf ein fremdes Pferd entstehen, sind mitversichert. Z. B. das Pferd galoppiert über die Straße und verursacht einen Unfall.',
        aboutLong:
          'Schäden, die beim Aufpassen auf ein fremdes Pferd entstehen, sind mitversichert. Z. B. das Pferd galoppiert über die Straße und verursacht einen Unfall.',
        value: 'mitversichert',
        position: 223,
      },
    ],
  },
  {
    name: 'Immobilien',
    position: 34,
    criterions: [
      {
        id: 27,
        name: 'Privater Schlüsselverlust',
        about:
          'Der Verlust von fremden privaten Schlüsseln ist bis zum genannten Betrag mitversichert. Z. B. der Schlüssel einer Mietwohnung geht verloren und das Schloss oder die Schließanlage muss draufhin ausgetauscht werden.',
        aboutLong:
          'Der Verlust von fremden privaten Schlüsseln ist bis zum genannten Betrag mitversichert. Z. B. der Schlüssel einer Mietwohnung geht verloren und das Schloss oder die Schließanlage muss draufhin ausgetauscht werden.',
        value: 'mind. 20.000 €',
        position: 225,
      },
      {
        id: 28,
        name: 'Allmählichkeitsschäden',
        about:
          'Allmählichkeitsschäden sind bis zum genannten Betrag mitversichert. Z. B. ein undichter Blumentopf steht monatelang auf dem Holzboden der gemieteten Wohnung und lässt das Holz aufquellen.',
        aboutLong:
          'Allmählichkeitsschäden sind bis zum genannten Betrag mitversichert. Z. B. ein undichter Blumentopf steht monatelang auf dem Holzboden der gemieteten Wohnung und lässt das Holz aufquellen.',
        value: 'mitversichert',
        position: 227,
      },
      {
        id: 29,
        name: 'Schäden durch häusliche Abwässer',
        about:
          'Schäden an fremden Sachen durch häusliche Abwässer sind bis zum genannten Betrag mitversichert. Z. B. Schäden an gemieteten Wohnungen oder der Wohnung des Nachbarn, durch ein gebrochenes Abwasserrohr.',
        aboutLong:
          'Schäden an fremden Sachen durch häusliche Abwässer sind bis zum genannten Betrag mitversichert. Z. B. Schäden an gemieteten Wohnungen oder der Wohnung des Nachbarn, durch ein gebrochenes Abwasserrohr.',
        value: 'mitversichert',
        position: 229,
      },
      {
        id: 30,
        name: '(Um-) Baumaßnahmen',
        about:
          'Das Bauherrenhaftpflichtrisiko ist im genannten Umfang mitversichert. Z. B. Ansprüche eines, durch einen herabfallender Dachziegel, verletzten Passanten.',
        aboutLong:
          'Das Bauherrenhaftpflichtrisiko ist im genannten Umfang mitversichert. Z. B. Ansprüche eines, durch einen herabfallender Dachziegel, verletzten Passanten.',
        value: 'mind. 100.000 €',
        position: 231,
      },
      {
        id: 32,
        name: 'Gewässerschaden-Risiko (Restrisiko)',
        about:
          'Gewässerschäden durch Kleingebinde, wie z. B. Behälter für Farben, Lacke, Verdünner usw., sind im genannten Umfang mitversichert.',
        aboutLong:
          'Gewässerschäden durch Kleingebinde, wie z. B. Behälter für Farben, Lacke, Verdünner usw., sind im genannten Umfang mitversichert.',
        value: 'Kleingebinde bis 50 l/kg  Gesamt 250 l/kg',
        position: 233,
      },
      {
        id: 34,
        name: 'Mieten einer Ferienwohnung im Ausland',
        about:
          'Schäden in und an gemieteten Hotelzimmern und Ferienwohnungen sind bis zum genannten Betrag mitversichert.',
        aboutLong:
          'Schäden in und an gemieteten Hotelzimmern und Ferienwohnungen sind bis zum genannten Betrag mitversichert.',
        value: 'mitversichert',
        position: 235,
      },
    ],
  },
  {
    name: 'Ausland',
    position: 35,
    criterions: [
      {
        id: 35,
        name: 'Auslandsaufenthalt: EU',
        about:
          'Der vorübergehende Auslandsaufenthalt innerhalb der EU ist für die angegebene Dauer mitversichert.',
        aboutLong:
          'Der vorübergehende Auslandsaufenthalt innerhalb der EU ist für die angegebene Dauer mitversichert.',
        value: '5 Jahre',
        position: 237,
      },
      {
        id: 36,
        name: 'Auslandsaufenthalt: weltweit',
        about:
          'Der weltweite Auslandsaufenthalt ist für die angegebene Dauer mitversichert.',
        aboutLong:
          'Der weltweite Auslandsaufenthalt ist für die angegebene Dauer mitversichert.',
        value: '5 Jahre',
        position: 239,
      },
    ],
  },
  {
    name: 'Tätigkeiten / Sonstiges',
    position: 36,
    criterions: [
      {
        id: 37,
        name: 'Tätigkeit als Tagesmutter',
        about:
          'Schäden, die durch die Tätigkeit als Tagesmutter verursacht werden, sind mitversichert.',
        aboutLong:
          'Schäden, die durch die Tätigkeit als Tagesmutter verursacht werden, sind mitversichert.',
        value: 'mitversichert',
        position: 241,
      },
      {
        id: 530,
        name: 'elektronischen Datenaustausch/Internetnutzung Europadeckung / Weltdeckung',
        about:
          'Schäden durch versehentliche Verbreitung von Viren oder Trojanern über das Internet sind im genannten Umfang mitversichert.',
        aboutLong:
          'Schäden durch versehentliche Verbreitung von Viren oder Trojanern über das Internet sind im genannten Umfang mitversichert.',
        value: 'mind. 5 Mio. €, weltweit',
        position: 243,
      },
      {
        id: 39,
        name: 'Ehrenamt (ohne öffentliche/hoheitliche Ehrenämter)',
        about:
          'Schäden, die im Rahmen einer ehrenamtliche Tätigkeit verursacht werden, sind mitversichert.',
        aboutLong:
          'Schäden, die im Rahmen einer ehrenamtliche Tätigkeit verursacht werden, sind mitversichert.',
        value: 'mitversichert',
        position: 245,
      },
      {
        id: 481,
        name: '(Wind-)Surfbretter (ohne Anzahlbegrenzung)',
        about:
          'Schäden, die durch den Besitz oder die Nutzung eigener und fremder Surfbretter verursacht werden, sind mitversichert.',
        aboutLong:
          'Schäden, die durch den Besitz oder die Nutzung eigener und fremder Surfbretter verursacht werden, sind mitversichert.',
        value: 'mitversichert',
        position: 247,
      },
      {
        id: 254,
        name: 'Kfz Be- und Entladeschäden',
        about:
          'Schäden an fremden Sachen durch das Be- und Entladen von Fahrzeugen sind im genannten Umfang mitversichert. Eine Meldung an die Kfz-Haftpflichtversicherung, verbunden mit einer Rückstufung des Schadenfreiheitsrabatts, ist somit nicht nötig.',
        aboutLong:
          'Schäden an fremden Sachen durch das Be- und Entladen von Fahrzeugen sind im genannten Umfang mitversichert. Eine Meldung an die Kfz-Haftpflichtversicherung, verbunden mit einer Rückstufung des Schadenfreiheitsrabatts, ist somit nicht nötig.',
        value: 'mind. 2.500 €',
        position: 249,
      },
      {
        id: 244,
        name: 'Mietsachschäden in Hotels- und Ferienwohnungen (Inventar)',
        about:
          'Schäden am Inventar gemieteter Hotelzimmer oder Ferienwohnungen sind im genannten Umfang mitversichert.',
        aboutLong:
          'Schäden am Inventar gemieteter Hotelzimmer oder Ferienwohnungen sind im genannten Umfang mitversichert.',
        value: 'mitversichert',
        position: 251,
      },
      {
        id: 245,
        name: 'Generelle Mietsachschadendeckung',
        about:
          'Schäden an privat geliehenen oder gemieteten beweglichen Sachen sind im genannten Umfang mitversichert.',
        aboutLong:
          'Schäden an privat geliehenen oder gemieteten beweglichen Sachen sind im genannten Umfang mitversichert.',
        value: 'mitversichert',
        position: 253,
      },
      {
        id: 241,
        name: 'Innovationsklausel',
        about:
          'Zukünftige beitragsfreie Leistungs-, bzw. Bedingungsverbesserungen gelten automatisch mitversichert.',
        aboutLong:
          'Zukünftige beitragsfreie Leistungs-, bzw. Bedingungsverbesserungen gelten automatisch mitversichert.',
        value: 'mitversichert',
        position: 255,
      },
      {
        id: 242,
        name: 'Leistungsgarantie GDV-Musterbedingungen',
        about:
          'Der Versicherer garantiert, dass er von den GDV-Musterbedingungen (Musterbedingungen des Gesamtverband der Deutschen Versicherungswirtschaft e. V.) nicht zum Nachteil des Versicherungsnehmers abweicht.',
        aboutLong:
          'Der Versicherer garantiert, dass er von den GDV-Musterbedingungen (Musterbedingungen des Gesamtverband der Deutschen Versicherungswirtschaft e. V.) nicht zum Nachteil des Versicherungsnehmers abweicht.',
        value: 'mitversichert',
        position: 257,
      },
    ],
  },
];

export { mockCriterionsArray, mockCriterion, mockTariffCriterions };
