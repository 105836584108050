<tariff-check-header></tariff-check-header>
<mat-toolbar class="second-toolbar">
  <div class="toolbar-title theme-color-primary center" data-cy="title">
    Produktauswahl
  </div>
</mat-toolbar>
<main>
  <mat-card class="card-container">
    @if (products) {
      <mat-tab-group animationDuration="0ms">
        @for (productGroup of productGroups; track productGroup.name; let i =
        $index) {
        <mat-tab
          label="{{ productGroup.name }}"
          [attr.data-cy]="'productGroup' + i"
        >
          <mat-selection-list
            [multiple]="false"
            (selectionChange)="onSelectionChange($event)"
            hideSingleSelectionIndicator="true"
          >
            @for (product of findByCategory(products, i); track product.id; let
            j = $index, last = $last) {
            <mat-list-option
              [id]="'product' + j"
              [attr.data-cy]="'product' + i + j"
              [value]="product"
              ><mat-icon
                mat-list-icon
                fontSet="far"
                [fontIcon]="product.icon"
                class="theme-color-primary"
              ></mat-icon
              >{{ product.name }} @if (!last) {
              <mat-divider></mat-divider>}</mat-list-option
            >
            } @empty {
            <p class="empty-list">
              Das Paket <strong>{{ productGroup.name }}</strong> wurde nicht
              freigeschaltet.
            </p>
            }
          </mat-selection-list>
        </mat-tab>
        }
      </mat-tab-group>
    }
  </mat-card>
</main>
