import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  sortArrayOfObjects = <T>(
    data: T[],
    keyToSort: keyof T,
    direction: 'asc' | 'desc' | ''
  ) => {
    if (direction === '') {
      return data;
    }
    const compare = (objectA: T, objectB: T) => {
      const valueA = objectA[keyToSort];
      const valueB = objectB[keyToSort];

      if (valueA === valueB) {
        return 0;
      }

      if (valueA > valueB) {
        return direction === 'desc' ? 1 : -1;
      } else {
        return direction === 'desc' ? -1 : 1;
      }
    };
    return data.slice().sort(compare);
  };

  testRegex(str: string, regex: RegExp): boolean {
    const result = regex.test(str);

    return result;
  }
}
