<mat-toolbar color="primary" class="mat-toolbar mat-toolbar-single-row">
  <div>
    <a routerLink="/home"
      ><img
        class="header-logo"
        src="assets/img/logos/smartinsurtech_neg.svg"
        alt="Logo"
    /></a>
  </div>
</mat-toolbar>
<main>
  <mat-card class="card-container">
    <mat-card-header>
      <mat-card-title>Einwilligung gemäß Datenschutz</mat-card-title>
      <mat-card-subtitle>
        Sind Sie mit den folgenden Nutzungszwecken einverstanden, kreuzen Sie
        diese bitte entsprechend an</mat-card-subtitle
      >
    </mat-card-header>
    <mat-card-content>
      <p>Einwilligungserklärung Datenschutz</p>
      <mat-checkbox
        color="primary"
        class="example-margin"
        [(ngModel)]="acceptedGdprChecked"
        >Mit Setzen des Häkchens erklären Sie sich einverstanden, dass die von
        Ihnen angegeben Daten elektronisch erhoben und gespeichert werden.
        Weitere Informationen entnehmen Sie der
        <a
          href="#"
          onclick="window.open('assets/doc/privacy.pdf', '_blank', 'fullscreen=yes'); return false;"
          >Datenschutzerklärung</a
        >.</mat-checkbox
      >
      <mat-divider></mat-divider>
      <p>Einwilligung in die Datennutzung zu weiteren Zwecken</p>
      <mat-checkbox
        color="primary"
        class="example-margin"
        [(ngModel)]="wantsWerbungChecked"
        >Ja, ich willige ein, dass mir die Smart InsurTech AG per E-Mail
        Informationen und Angebote zu weiteren Produkten und Dienstleistungen
        zum Zwecke der Werbung übersendet.</mat-checkbox
      >
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-button
        color="primary"
        (click)="complianceRefused()"
        data-cy="complianceRefused"
      >
        Ablehnen
      </button>
      <button
        mat-flat-button
        id="analyseBtn"
        color="primary"
        [disabled]="!acceptedGdprChecked"
        (click)="complianceAccepted()"
        data-cy="complianceAccepted"
      >
        Bestätigen
      </button>
    </mat-card-actions>
  </mat-card>
</main>
