import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Injector,
} from '@angular/core';
import {
  ProductType,
  ProductService,
  TariffMail,
  TariffService,
} from '@smart-check/feature-comparison';
import { NotificationService } from '@smart-check/shared/ui';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'tariff-report',
  templateUrl: './tariff-report.component.html',
})
export class TariffReportComponent implements OnInit {
  public reportTariffForm: FormGroup = this.formBuilder.group({});
  public products: Array<ProductType>;
  public responseMessage: string;
  public attachment: File;
  public loadingSend: number = 0;
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private tariffService: TariffService,
    private route: ActivatedRoute,
    private injector: Injector
  ) {
    this.reportTariffForm = new FormGroup({
      productTypeControl: new FormControl('', [Validators.required]),
      companyControl: new FormControl('', [Validators.required]),
      tariffNameControl: new FormControl('', [Validators.required]),
      termControl: new FormControl('', [Validators.required]),
      emailControl: new FormControl('', [Validators.required]),
      noticeControl: new FormControl(''),
    });
  }

  @Output() closeSidenav = new EventEmitter();
  @ViewChild('sidenav') sidenav: MatSidenav;

  ngOnInit(): void {
    this.productService
      .getCurrentProductType(
        parseInt(this.route.snapshot.paramMap.get('product')!)
      )
      .subscribe({
        next: (response: ProductType) => {
          this.reportTariffForm.controls['productTypeControl'].setValue(
            response.name
          );
        },
      });
    this.productService
      .getProductTypeSorted(false)
      .subscribe((response: Array<ProductType>) => (this.products = response));
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.attachment = file;
    }
  }

  sendReport(reportTariffForm: FormGroup) {
    this.loadingSend = 1;
    const tariffMail: TariffMail = {
      productType: reportTariffForm.get('productTypeControl')?.value,
      company: reportTariffForm.get('companyControl')?.value,
      tariffName: reportTariffForm.get('tariffNameControl')?.value,
      terms: reportTariffForm.get('termControl')?.value,
      file: this.attachment,
      email: reportTariffForm.get('emailControl')?.value,
      notice: reportTariffForm.get('noticeControl')?.value,
    };
    this.tariffService.newTariffMail(tariffMail).subscribe({
      next: (response) => {
        if (response === 0) {
          const notifier = this.injector.get(NotificationService);
          notifier.showSuccess(
            'Wir haben Ihre Nachricht erhalten und werden den fehlenden Tarif schnellstmöglich einpflegen.'
          );
        }
      },
      complete: () => {
        this.loadingSend = 2;
        this.closeSidenav.emit();
      },
    });
  }
}
