import { DialogSupportComponent } from './../dialog/dialog-support/dialog-support.component';
import { DialogLegalsComponent } from './../dialog/dialog-legals/dialog-legals.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@smart-check/feature-comparison';
import { ThemeService, AuthService } from '@smart-check/shared/ui';

@Component({
  selector: 'tariff-check-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public user = JSON.parse(localStorage.getItem('user')!);
  public scheme = localStorage.getItem('scheme')!;
  public isLogoLoaded: boolean;
  public logo: string | ArrayBuffer | null;
  public userInitials = '';
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private themeService: ThemeService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.user.firstName && this.user.lastName) {
      this.userInitials =
        this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
    }

    this.userService.getUsergroupLogo().subscribe({
      next: (response: Blob) => {
        this.createImageFromBlob(response);
        this.isLogoLoaded = true;
      },
      error: () => {
        this.isLogoLoaded = false;
      },
    });

    this.setScheme(this.user.userGroupId);
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.logo = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  setScheme(userGroupId: number): void {
    this.themeService.setTheme(userGroupId);
    this.themeService.setFavicon(userGroupId);
  }

  openDialog(name: string): void {
    if (name == 'support') {
      this.dialog.open(DialogSupportComponent, {
      });
    } else {
      this.dialog.open(DialogLegalsComponent, {
      });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['home']);
  }
}
