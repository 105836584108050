import { Injectable } from '@angular/core';
import posthog, { PostHogConfig, Properties } from 'posthog-js';

export type EventProperties = Properties;

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  public init(token: string, options: Partial<PostHogConfig>): void {
    posthog.init(token, options);
  }

  public reset(): void {
    posthog.reset();
  }

  public capturePageview(): void {
    posthog.capture('$pageview');
  }

  public identifyUser(user: any): void {
    posthog.identify(String(user.userId), {
      userId: user.mdCustomerId,
    });
  }

  public capture(eventName: string, properties: EventProperties): void {
    posthog.capture(eventName, properties);
  }
}
