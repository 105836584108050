import { CurrentUser, User, UserScheme } from '@smart-check/shared/ui';

const mockScheme: UserScheme = {
  colorScheme: 'smartinsurtech',
  vergleicheColorScheme: 'default',
};

const mockCurrentUser: CurrentUser = {
  csrfToken: 'token',
  email: 'bud@snirgel.de',
  smitId: 'null',
  userName: 'bud@snirgel.de',
  firstName: 'Bud',
  lastName: 'Spencer',
  userGroupId: 24,
  scheme: mockScheme,
  acceptedGdpr: 1,
  wantsWerbung: 1,
  productTypeIds: [17, 23, 7, 17, 23, 18, 19, 21, 22, 24],
  mdCustomerId: 21345,
};

const mockCurrentUserCompliance: CurrentUser = {
  csrfToken: 'token',
  email: 'bud@snirgel.de',
  smitId: 'null',
  userName: 'bud@snirgel.de',
  firstName: 'Bud',
  lastName: 'Spencer',
  userGroupId: 24,
  scheme: mockScheme,
  acceptedGdpr: 0,
  wantsWerbung: 0,
};

const mockBlockedUser: CurrentUser = {
  csrfToken: 'null',
  email: 'null',
  smitId: 'null',
  userName: 'null',
  firstName: 'null',
  lastName: 'null',
  userGroupId: 0,
  scheme: mockScheme,
  acceptedGdpr: 0,
  wantsWerbung: 0,
};

const mockUserLoginFail: User = {
  currentUser: mockCurrentUser,
  result: 2,
  resultText: 'ERROR.\nThe password was wrong.',
};

const mockUserLoginSuccess: User = {
  currentUser: mockCurrentUser,
  result: 0,
  resultText: 'OK.\nLogged In',
};

const mockUserLoginSuccessCompliance: User = {
  currentUser: mockCurrentUserCompliance,
  result: 0,
  resultText: 'OK.\nLogged In',
};

const mockUserLoginTooManyFails: User = {
  currentUser: mockBlockedUser,
  result: 5,
  resultText: '4:28',
};

export {
  mockCurrentUser,
  mockUserLoginSuccess,
  mockUserLoginFail,
  mockUserLoginSuccessCompliance,
  mockUserLoginTooManyFails,
};
