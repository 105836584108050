const mockSidenav0: object = {
  id: 0,
  title: 'Produktauswahl',
};

const mockSidenav1: object = {
  id: 1,
  title: 'Fehlenden Tarif melden',
};

const mockSidenav2: object = {
  id: 2,
  title: 'Tarif drucken',
};

const mockSidenav3: object = {
  id: 3,
  title: 'Tarif per E-Mail versenden',
};

const mockSidenavDefault: object = {
  id: 99,
  title: '',
};

export {
  mockSidenav0,
  mockSidenav1,
  mockSidenav2,
  mockSidenav3,
  mockSidenavDefault,
};
