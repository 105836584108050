<mat-toolbar class="mat-toolbar mat-toolbar-single-row">
  <div>
    <a routerLink="/home"
      ><img
        class="header-logo"
        src="assets/img/logos/smartinsurtech_neg.svg"
        alt="Logo"
    /></a>
  </div>
</mat-toolbar>
<main>
  @if (badRequest) {
  <mat-card class="card-container">
    <mat-card-header>
      <mat-card-title>Smart Check</mat-card-title>
      <mat-card-subtitle>Passwort neu festlegen</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content
      >Der Link ist abgelaufen, klicken Sie auf den Button Schließen und fordern
      Sie sich über die Funktion "Passwort vergessen" eine neuen
      an.</mat-card-content
    >
    <div class="mat-card-actions-align-end">
      <button mat-button mat-dialog-close (click)="close()">Schließen</button>
    </div>
  </mat-card>
  } @else {
  <mat-card class="card-container">
    <form [formGroup]="resetPasswordForm" novalidate>
      <mat-card-header>
        <mat-card-title>Smart Check</mat-card-title>
        <mat-card-subtitle> Passwort neu festlegen</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>Neues Passwort</mat-label>
          <input
            [type]="newPasswordHidden ? 'password' : 'text'"
            aria-label="Neues Passwort"
            matInput
            formControlName="newPasswordControl"
          />
          <mat-icon
            matSuffix
            (click)="newPasswordHidden = !newPasswordHidden"
            >{{ newPasswordHidden ? 'visibility_off' : 'visibility' }}</mat-icon
          >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Neues Passwort bestätigen</mat-label>
          <input
            [type]="newPasswordRepeatHidden ? 'password' : 'text'"
            aria-label="Neues Passwort bestätigen"
            matInput
            formControlName="newPasswordRepeatControl"
            (focusout)="focusOutNewPassword()"
          />
          <mat-icon
            matSuffix
            (click)="newPasswordRepeatHidden = !newPasswordRepeatHidden"
            >{{
              newPasswordRepeatHidden ? 'visibility_off' : 'visibility'
            }}</mat-icon
          >
          <mat-error>Passwörter stimmen nicht überein </mat-error>
        </mat-form-field>
      </mat-card-content>
      <div class="mat-card-actions-align-end">
        <button mat-button (click)="resetPasswordForm.reset()">
          RÜCKSETZEN
        </button>
        <button
          mat-flat-button
          [disabled]="!resetPasswordForm.valid"
          (click)="setNewPassword(resetPasswordForm, csrf2)"
        >
          PASSWORD ÄNDERN
        </button>
      </div>
    </form>
  </mat-card>
  }
</main>
