import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, CurrentUser } from '@smart-check/shared/ui';

@Component({
  selector: 'smart-check-impersonate',
  template: '',
})
export class ImpersonateComponent implements OnInit {
  public user: CurrentUser;
  public token: string;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.route.snapshot.paramMap.get('token')!;
    this.authService.getImpersonateIdentity(this.token).subscribe({
      next: (response: CurrentUser) => {
        this.user = response;
      },
      complete: () => {
        if (this.user.csrfToken) {
          localStorage.setItem('user', JSON.stringify(this.user));
          const sessionUser = JSON.parse(localStorage.getItem('user')!);
          localStorage.setItem('scheme', sessionUser.scheme.colorScheme);
          this.router.navigate(['productSelect']);
        }
      },
    });
  }
}
