export class TariffParams {
  constructor(
    public idOrSlug: number | string,
    public withCrit: boolean = false,
    public onlyActive: boolean = true
  ) {
    this.idOrSlug = idOrSlug;
    this.withCrit = withCrit;
    this.onlyActive = onlyActive;
  }
}

export class YearsParams {
  constructor(
    public refTariffId: number,
    public companyId: number,
    public onlyActive: boolean
  ) {
    this.refTariffId = refTariffId;
    this.companyId = companyId;
    this.onlyActive = onlyActive;
  }
}

export class TariffByYearParams {
  constructor(
    public referenceTariffId: number,
    public companyId: number,
    public year: string = '-1',
    public onlyActive: boolean = true
  ) {
    this.referenceTariffId = referenceTariffId;
    this.companyId = companyId;
    this.year = year;
    this.onlyActive = onlyActive;
  }
}
export class TariffVariantsParams {
  constructor(public idOrSlug: number | string) {
    this.idOrSlug = idOrSlug;
  }
}
export class VariantsParams {
  constructor(public idOrSlug: number | string) {
    this.idOrSlug = idOrSlug;
  }
}
export class CompaniesParams {
  constructor(public idOrSlug: number | string, public topFlag: boolean) {
    this.idOrSlug = idOrSlug;
    this.topFlag = topFlag;
  }
}
export class HasSeenInfoParams {
  constructor(public slug: string, public toggle: boolean = false) {
    this.slug = slug;
    this.toggle = toggle;
  }
}
export class ProductTypeParams {
  constructor(public withCriterion: boolean = false) {
    this.withCriterion = withCriterion;
  }
}
export class ConsumptionParams {
  constructor(public von: string, public bis: string) {
    this.von = von;
    this.bis = bis;
  }
}
export class LogoParams {
  constructor(public idOrSlug: number | string) {
    this.idOrSlug = idOrSlug;
  }
}

export class CriterionsParams {
  constructor(public productType: number) {
    this.productType = productType;
  }
}
