const mockSecurityToken = new FormData();
mockSecurityToken.append(
  'token',
  '48AF7181F31CDE5169AC75A5B0638334C5BA5A1F5947E0714B214ABBBC728950'
);

const mockCsrf: string =
  '48AF7181F31CDE5169AC75A5B0638334C5BA5A1F5947E0714B214ABBBC728950';

export { mockSecurityToken, mockCsrf };
